.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8fafc;
}

.pageLayout {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.mainContent {
  flex: 1;
  background-color: #ffffff00;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.wrapper {
position: relative;
  flex: 1;
  padding: 35px 24px;
  height: calc(100vh - 112px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.title {
  position: relative;
  top: 10px;
  left: 0%;
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  font-family: Roboto;
  color: #004788;
  margin-bottom: 32px;
  padding-left: 24px;
}
.submittedEndeavorsTitle {
    position: relative;
    top: 10px;
    left: 0%;
    font-size: 30px;
    line-height: 25px;
    font-weight: 500;
    font-family: Roboto;
    color: #004788;
    margin-bottom: 32px;
    padding-left: 24px;
}

/* AI Generator Card */
.aiGeneratorCard {
  min-width: 575px;
  background-color: #fff;
  border: 1px solid #dde2e5;
  border-radius: 20px;
  padding: 37px 43px;
  margin-bottom: 32px;
  position: relative;
  top: 20px;
}

.generatorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #dde2e5;
}
.addEndeavorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #dde2e5;
  margin-bottom: 24px;
  margin-top: 24px;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  font-family: 'Open Sans', sans-serif;
}

.aiIcon {
  font-size: 20px;
}

.collapseButton {
  background: none;
  border: none;
  cursor: pointer;
  color: #6B7280;
}

.generatorContent {
  padding: 24px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-family: 'Open Sans', sans-serif;
}

.actionButtons {
  display: flex;
  gap: 16px;
}

.generateButton {
  width: 200px;
  border-radius: 12px;
  padding: 14px 24px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e70bb;
  color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(30, 112, 187, 0.2);
  transition: all 0.2s ease;
}

.generateButton:hover {
  background-color: #1a62a3;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(30, 112, 187, 0.25);
}

.editProfileButton {
  width: 200px;
  border-radius: 12px;
  padding: 14px 24px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  /* cursor: pointer; */
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e70bb;
  color: #fff;
}

/* Add Endeavor Section */
.addEndeavorSection {
  border: 2px dashed #E5E7EB;
  border-radius: 8px;
  padding: 24px;
  display: flex;
  justify-content: center;
}

.addEndeavorButton {
  padding: 8px 16px;
  background: none;
  border: none;
  color: #2563EB;
  font-weight: 500;
  cursor: pointer;
}

.endeavorForm {
  background: white;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-top: 1rem;
}

.formSection {
  margin-bottom: 1.5rem;
}

.formSection h3 {
  color: #333;
  margin-bottom: 0.5rem;
  font-size: 1.1rem;
  font-family: 'Open Sans', sans-serif;
}

.textInput {
  width: 100%;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
}

.textarea {
  width: 100%;
  min-height: 100px;
  padding: 0.75rem;
  border: 1px solid #ddd;
  border-radius: 4px;
  font-size: 1rem;
  margin-bottom: 1rem;
  resize: vertical;
}

.subSection {
  padding-left: 1rem;
  border-left: 2px solid #eee;
}

.formActions {
  display: flex;
  gap: 1rem;
  margin-top: 1rem;
}

.submitButton {
  padding: 0.75rem 1.5rem;
  background-color: #1e70bb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.submitButton:hover {
  background-color: #1e70bb;
}

.cancelButton {
  padding: 0.75rem 1.5rem;
  background-color: #6c757d;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1rem;
}

.cancelButton:hover {
  background-color: #5a6268;
}

.buttoncontainer {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 32px;
	padding-top: 16px;
	border-top: 1px solid #E6E6E6;
}
/* Submitted Endeavor Card */
.submittedEndeavor {
  position: relative;
  background: #ffffff;
  border: 1px solid #e0e0e0;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.2s ease;
}

.submittedEndeavor:hover {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  transform: translateY(-1px);
}

/* Header with Checkbox and Title */
.endeavorHeader {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 12px;
  gap: 12px;
}

.checkboxContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.endeavorCheckbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  accent-color: #1e70bb;
}

.endeavorTitle {
  flex: 1;
  margin: 0;
}

.dropdownButton {
  background: none;
  border: none;
  color: #1e70bb;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
  transition: transform 0.2s ease;
}

.dropdownButton.expanded {
  transform: rotate(180deg);
}

/* Content Details */
.endeavorDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-left: 36px;
  font-family: 'Open Sans', sans-serif;
}

.endeavorDetails h3 {
  font-size: 15px;
  font-weight: 600;
  color: #004788;
  margin: 0;
  padding-top: 12px;
}

.endeavorDetails p {
  margin: 6px 0;
  line-height: 1.5;
  color: #4a5568;
  font-size: 14px;
}

/* Merit and National Importance Sections */
.meritSection, 
.nationalImportanceSection {
  background: #f8fafc;
  border-radius: 8px;
  padding: 16px;
  margin-top: 4px;
}

.meritSection p, 
.nationalImportanceSection p {
  display: flex;
  align-items: baseline;
  gap: 12px;
  margin: 8px 0;
}

.meritSection strong, 
.nationalImportanceSection strong {
  min-width: 110px;
  color: #2d3748;
  font-weight: 600;
  font-size: 14px;
}

/* Main Container Section */
.endeavorsContainer {
  /* width: 100%;
  max-width: 900px;
  border: 2px dashed #e2e8f0;
  border-radius: 12px;
  padding: 32px;
  margin: 24px 0;
  background: white;
  position: relative; */

  min-width: 575px;
  background-color: #fff;
  border: 1px solid #dde2e5;
  border-radius: 20px;
  padding: 37px 43px;
  margin-bottom: 32px;
  position: relative;
  top: 20px;
}

/* Add New Endeavor Button */
.addEndeavorButton {
  width: 100%;
  padding: 16px;
  background: none;
  border: 2px dashed #e2e8f0;
  border-radius: 8px;
  color: #1e70bb;
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.addEndeavorButton:hover {
  background-color: #f8fafc;
  border-color: #1e70bb;
}

.endeavorActions {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.iconButton {
  background: none;
  border: none;
  cursor: pointer;
  padding: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  transition: background-color 0.2s;
  color: #1e70bb;
}

.lockedBadge {
  position: absolute;
  left: 30px;
  background-color: #f8d7da;
  color: #721c24;
  padding: 2px 8px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
}

.endeavorCheckbox:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.endeavorCheckbox:disabled + label {
  opacity: 0.6;
}
