.navbarIcon {
    position: absolute;
    top: 58px;
    left: 92px;
    width: 185px;
    height: 54px;
}
.createAccount {
    position: absolute;
    top: 155px;
    left: 530px;
    font-size: 30px;
    letter-spacing: -0.01em;
    line-height: 130%;
    display: inline-block;
    font-family: Poppins;
    width: 339px;
}
.textContainer {
    position: relative;
    width: 100%;
    background-color: #fcfcfc00;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 16px;
}
.iconInsideInput {
    background: #ffffff00;
    border-color: #fcfcfc00;
    color: #3c5a99;
    flex-direction: row;
  }
.iAcceptThe {
    position: relative;
    top: -20px;
    left: 30px;
    line-height: 120%;
    display:inline-block;
    align-items: flex-end;
    width: 100%;
    height: 20px;
}
.iconButton {
    background: #ffffff00;
    border-color: #fcfcfc00;
    color: #3c5a99;
}
.span {
    color: #3c5a99;
    white-space: pre-wrap;
}
.logIn1 {
    text-decoration: underline;
    font-weight: 600;
}
.alreadyHaveAnContainer {
    position: absolute;
    top: 720px;
    left: 599px;
    line-height: 125%;
    color: rgba(0, 0, 0, 0.7);
    align-items: center;

}
.title {
    position: relative;
    line-height: 125%;
}
.text {
    flex: 1;
    position: relative;
    line-height: 125%;
}

.inputField {
    width: 353px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d8dadc;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
}
.input {
    position: absolute;
    top: 246px;
    left: 532px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
}
/* .textContainer {
    width: 390px;
    background-color: #fcfcfc00;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 16px;
    right:-10000px
} */
.input1 {
    position: absolute;
    top: 348px;
    left: 532px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
}
.icon {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}
.inputField2 {
    width: 353px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d8dadc;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 16px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.5);
}
.input2 {
    position: absolute;
    top: 450px;
    left: 532px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
    /* width: 353px; */
}
.registrationChild {
    position: absolute;
    top: 499px;
    left: 670px;
    width: 76px;
    height: 6px;
}
.checkboxOn {
    position: absolute;
    top: 600px;
    left: 550px;
    width: 300px;
    height: 20px;
    align-items: center;
}
.button {
    position: relative;
    line-height: 125%;
    font-weight: 600;
}
.buttonPrimary {
    position: absolute;
    top: 645px;
    left: 532px;
    border-radius: 10px;
    background-color: #3c5a99;
    width: 353px;
    height: 56px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 17px 152px; */
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border: 1px solid #d8dadc;
}
.errorText {
    color: red;
    font-size: 12px;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    margin-top: 5px;
}
.registration {
    width: 100%;
    position: relative;
    background-color: #fff;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #3c5a99;
    font-family: Inter;
    align-items: center;
}

.popup-message {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 20px;
    border-radius: 10px;
    color: #fff;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    z-index: 1000;
    font-size: 16px;
    text-align: center;
  }
  
  .popup-message.success {
    background-color: rgba(0, 128, 0, 0.9);
  }
  
  .popup-message.error {
    background-color: rgba(255, 0, 0, 0.9);
  }
  
  .link {
    color: #3182ce;
    text-decoration: none;
    transition: color 0.2s ease;
  }
  .link:hover {
    color: #2c5282;
    text-decoration: underline;
  }