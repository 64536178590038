.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8fafc;
  }
  
  .pageLayout {
    display: flex;
    padding: 20px;
    gap: 20px;
  }
  
  .mainContent {
    flex: 1;
    background-color: #ffffff00;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper {
    position: relative;
    flex: 1;
    padding: 35px 24px;
    height: calc(100vh - 112px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  .plansContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .planCard {
    background: white;
    border-radius: 8px;
    padding: 24px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    align-items: center;
    text-align: center;
  }
  
  .planCard h3 {
    font-size: 20px;
    margin-bottom: 16px;
    color: #333;
  }
  
  .price {
    font-size: 24px;
    font-weight: bold;
    color: #2c5282;
    margin: 8px 0;
  }
  
  .originalPrice {
    text-decoration: line-through;
    color: #666;
    font-size: 16px;
  }
  
  .features {
    margin: 16px 0;
    text-align: center;
    flex-grow: 1;
  }
  
  .features p {
    margin: 8px 0;
    color: #4a5568;
  }
  
  .planButton {
    width: 100%;
    padding: 10px;
    margin: 16px 0;
    border-radius: 4px;
    background-color: #004788;
    color: white;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  .sectionTitle {
    font-size: 20px;
    color: #2c5282;
    margin-bottom: 16px;
    text-align: left;
  }
  
  .planButton:hover {
    background-color: #1d67e7;
  }
  
  .addon {
    font-size: 14px;
    color: #666;
    margin-top: 8px;
  }
  
  .addOnsContainer {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
    gap: 20px;
    margin-bottom: 40px;
  }
  
  .addOnCard {
    background: white;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .addOnCard h3 {
    font-size: 18px;
    margin-bottom: 12px;
    color: #333;
  }
  
  .notes {
    background: white;
    border-radius: 8px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .notes ol {
    margin-left: 20px;
    color: #4a5568;
  }
  
  .notes li {
    margin: 8px 0;
  }
  .title {
    position: relative;
    top: 10px;
    left: 0%;
    font-size: 30px;
    line-height: 25px;
    font-weight: 500;
    font-family: Roboto;
    color: #004788;
    margin-bottom: 32px;
    padding-left: 24px;
  }
  
  .introSection,
  .rfeSection {
    margin: 2rem 0;
    padding: 1rem 0;
    border-bottom: 1px solid #eaeaea;
  }
  
  .subtitle {
    font-size: 1.1rem;
    color: #666;
    line-height: 1.5;
    max-width: 800px;
    margin: 0 auto;
    text-align: center;
  }
  
  .buttonContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
  }
  
  .quantitySelector {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    margin-bottom: 15px;
  }
  
  .quantityButton {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid #4a5568;
    background: white;
    color: #4a5568;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    transition: all 0.2s;
  }
  
  .quantityButton:hover {
    background: #4a5568;
    color: white;
  }
  
  .quantityDisplay {
    min-width: 30px;
    text-align: center;
    font-size: 16px;
    color: #4a5568;
  }
  
  .headerSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .cartButton {
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 8px 16px;
    background-color: #0056b3;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .cartButton:hover {
    background-color: #0056b3;
  }