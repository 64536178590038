.container {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
  }
  
  .pageLayout {
    display: flex;
    flex: 1;
  }
  
  .mainContent {
    flex: 1;
    padding: 2rem;
    max-width: 800px;
    margin: 0 auto;
  }
  
  .title {
    font-size: 2rem;
    margin-bottom: 1.5rem;
    color: #333;
  }
  
  .contactInfo {
    margin-bottom: 2rem;
    line-height: 1.6;
  }
  
  .contactForm {
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .formGroup {
    margin-bottom: 1.5rem;
  }
  
  .formGroup label {
    display: block;
    margin-bottom: 0.5rem;
    font-weight: 500;
    color: #333;
  }
  
  .formGroup input,
  .formGroup textarea {
    width: 100%;
    padding: 0.75rem;
    border: 1px solid #ddd;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .formGroup textarea {
    resize: vertical;
    min-height: 120px;
  }
  
  .formGroup input:focus,
  .formGroup textarea:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
  }
  
  .submitButton {
    width: 100%;
    padding: 1rem;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 1rem;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .submitButton:hover {
    background-color: #0056b3;
  }
  
  .submitButton:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }