.pricingCard {
    position: relative;
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 12px;
    padding: 32px;
    transition: all 0.3s ease;
    display: flex;
    flex-direction: column;
    min-height: 420px;
}

.pricingCard:hover {
    transform: translateY(-4px);
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.06);
}

.cardHeader {
    margin-bottom: 16px;
}

.title {
    font-family: 'Mukta Malar', sans-serif;
    font-size: 24px;
    font-weight: 700;
    color: #000000;
    letter-spacing: -0.02em;
}

.features {
    list-style: none;
    padding: 0;
    margin: 0 0 auto 0;
    flex-grow: 1;
}

.featureItem {
    display: flex;
    align-items: flex-start;
    gap: 12px;
    margin-bottom: 16px;
    color: #4B5563;
    font-size: 14px;
    line-height: 1.5;
}

.bulletPoint {
    color: #666666;
    font-size: 20px;
    line-height: 1;
    margin-top: 2px;
}

.featureText {
    font-family: 'DM Sans', sans-serif;
    font-size: 16px;
    font-weight: 300;
    line-height: 1.5;
    color: #666666;
}

.bottomSection {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: auto;
    padding-top: 24px;
    border-top: 1px solid #E5E7EB;
    gap: 16px;
}

.priceSection {
    flex-shrink: 0;
}

.originalPrice {
    font-size: 14px;
    color: #6B7280;
    text-decoration: line-through;
    margin-bottom: 4px;
}

.currentPrice {
    display: flex;
    align-items: baseline;
    gap: 1px;
}

.dollarSign {
    font-size: 18px;
    font-weight: 600;
    color: #111827;
}

.amount {
    font-size: 28px;
    font-weight: 700;
    color: #111827;
    line-height: 1;
}

.period {
    font-size: 14px;
    color: #6B7280;
    margin-left: 2px;
}

.getStarted {
    background: #4B96F8;
    color: #FFFFFF;
    font-size: 14px;
    font-weight: 500;
    border: none;
    border-radius: 6px;
    padding: 8px 20px;
    height: 36px;
    min-width: 100px;
    cursor: pointer;
    transition: all 0.2s ease;
    white-space: nowrap;
    flex-grow: 1;
    max-width: 120px;
}

.getStarted:hover {
    background: #1D4ED8;
}

.pricingCardFeatured {
    background: #EFF6FF;
    border-color: #60A5FA;
    border-width: 2px;
    margin-top: 12px;
}

.pricingCardFeatured .getStarted {
    background: #2563EB;
}

.pricingCardFeatured .getStarted:hover {
    background: #1D4ED8;
}

.divider {
    position: relative;
    text-align: center;
    margin: 32px 0;
    border-bottom: 1px solid #E5E7EB;
}

.dividerText {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #FFFFFF;
    padding: 0 12px;
    color: #6B7280;
    font-size: 14px;
    font-weight: 500;
}

.pricingCardFeatured .divider {
    border-bottom-color: #BFDBFE;
}

.popularLabel {
    position: absolute;
    top: -12px;
    left: 50%;
    transform: translateX(-50%);
    background: #2563EB;
    color: white;
    font-size: 14px;
    font-weight: 500;
    padding: 4px 16px;
    border-radius: 20px;
    white-space: nowrap;
    box-shadow: 0 2px 4px rgba(37, 99, 235, 0.2);
}

@media (max-width: 768px) {
    .pricingCard {
        padding: 24px;
        min-height: auto;
    }

    .bottomSection {
        padding-top: 20px;
    }

    .getStarted {
        max-width: 100px;
        padding: 8px 16px;
    }
}