/* Main layout */
.createProfile {
	flex: 1;
	background-color: #ffffff00;
	border-radius: 12px;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

/* Menu/Sidebar styles */
.menu {
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 240px;
	height: 100vh;
	background-color: #fff;
	box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.1);
	position: fixed;
	left: 0;
	top: 0;
}

.top {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 44px;
}

.profile {
	display: flex;
	align-items: center;
	gap: 12px;
	width: 100%; /* Add this */
}

.text {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	/* gap: 4px; */
	width: 100%; /* Add this */
}

.logo {
	width: 140px;
	height: 140px;
	object-fit: contain;
	top: -100px;
}

.duckUi {
	font-size: 16px;
	line-height: 24px;
	color: #1a1a1a;
	font-family: 'Open Sans', sans-serif;
}

.duckuidemocom {
	font-size: 14px;
	line-height: 20px;
	color: #666;
	font-family: 'Open Sans', sans-serif;
}

.listitem {
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	gap: 24px;
	width: 100%; /* Add this */
}

.listManu {
	border-radius: 8px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: flex-start;
	padding: 16px;
	cursor: pointer;
	width: 100%; /* Add this */
}

.listManu:hover {
	background-color: #f5f5f5;
}

.content {
	width: 186px;
	height: 24px;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	gap: 16px;
	font-family: 'Open Sans', sans-serif;
}

.text2 {
	font-size: 14px;
	line-height: 20px;
	color: #1a1a1a;
	font-family: 'Open Sans', sans-serif;
}

/* Main content wrapper */
.wrapper {
	padding: 24px;
	height: calc(100vh - 112px); /* 64px header + 24px padding top + 24px padding bottom */
	overflow-y: auto;
}

.createYourProfile {
	font-size: 30px;
	line-height: 25px;
	font-weight: 500;
	font-family: Roboto;
	color: #004788;
	margin-bottom: 32px;
	padding-left: 24px;
}

.container {
	min-height: 100vh;
	display: flex;
	flex-direction: column;
	background-color: #f8fafc;
}

/* Form container styles */
.formcontainer {
	max-width: 1000px;
  position: relative;
	margin: 0 auto;
	padding: 0px;
}

.innercontainer {
	max-width: 400px;
	margin: 0 auto;
}

.formSection {
	background-color: #fff;
	border: 1px solid #dde2e5;
	border-radius: 20px;
	padding: 37px 43px;
	margin-bottom: 32px;
}

.personalInformation {
	position: relative;
	font-size: 25px;
}

/* Stepper styles */
.stepper {
	align-self: stretch;
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	justify-content: flex-start;
	gap: 16px;
	text-align: right;
	font-size: 14px;
	color: #1e70bb;
	margin-bottom: 32px; 
}

.step1 {
	font-weight: 500;
}
.of4 {
	color: #83898c;
}
.barcontainer {
	align-self: stretch;
	position: relative;
	height: 12px;
	width: 100%;
}
.bg {
	position: absolute;
	top: 3px;
	left: 0px;
	right: 0px;
	border-radius: 20px;
	background-color: #e2e6f90f;
	/* width: 100%; */
	height: 6px;
}
.bar {
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 20px;
	background-color: #1e70bb;
	width: 25%;
	height: 12px;
	transition: width 0.3s ease;
}

/* Form input styles */
.inputcontainer {
	display: flex;
	flex-direction: column;
	gap: 24px;
}

.row {
	display: flex;
	gap: 24px;
}

.leftcol {
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 24px;
}

/* Common input styles */
.inputContent,
.inputContent1,
.inputContent3,
.inputContent4,
.titleDropdown,
.genderDropdown,
.countryCodeSelect {
	width: 50%;
	padding: 12px;
	font-size: 16px;
	color: #6c757d;
	background-color: rgba(210, 217, 244, 0.6);
	border: 1px solid #ced4da;
	border-radius: 8px;
	box-sizing: border-box;
	transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out;
}
.inputContent,
.inputContent1,
.inputContent3,
.inputContent4:focus {
	border-color: #1e70bb;
	background-color: rgba(239, 241, 249, 0.6);
	outline: none;
}

/* Dropdown specific styles */
.titleDropdown,
.genderDropdown,
.countryCodeSelect {
	background-color: #FFFFFF;
	cursor: pointer;
	appearance: none;
	padding: 12px 16px;
	font-size: 16px;
	border: 1px solid #E6E6E6;
	border-radius: 8px;
	color: #333;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
	background-image: url("data:image/svg+xml,%3Csvg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M1 1.5L6 6.5L11 1.5' stroke='%23666666' stroke-width='2' stroke-linecap='round' stroke-linejoin='round'/%3E%3C/svg%3E");
	background-repeat: no-repeat;
	background-position: right 16px center;
	transition: all 0.2s ease;
}

.titleDropdown:hover,
.genderDropdown:hover,
.countryCodeSelect:hover {
	border-color: #1e70bb;
	box-shadow: 0 2px 8px rgba(30, 112, 187, 0.15);
}

.titleDropdown:focus,
.genderDropdown:focus,
.countryCodeSelect:focus {
	border-color: #1e70bb;
	box-shadow: 0 2px 8px rgba(30, 112, 187, 0.2);
	outline: none;
}

.countryCodeSelect {
	width: 160px;
	min-width: fit-content;
}

/* Phone input group */
.phoneInputGroup {
	display: flex;
	gap: 8px;
	width: 50%;
}

.phoneInput {
	background-color: rgba(239, 241, 249, 0.6);
	flex: 1;
	height: 40px;
	padding: 8px 16px;
	border: 1px solid #E6E6E6;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	color: #1e70bb;
}

/* File upload styles */
.fileUploadContainer {
	margin-top: 8px;
}

.fileInput {
	display: none;
}

.fileInputLabel {
	display: block;
	width: 25%;
	height: 40px;
	padding: 8px 16px;
	border: 1px solid #E6E6E6;
	border-radius: 4px;
	font-size: 14px;
	line-height: 20px;
	background-color: #fff;
	cursor: pointer;
	overflow: hidden;
	text-overflow: ellipsis;
	white-space: nowrap;
	font-family: 'Open Sans', sans-serif;
	color: #6f6d6d;
}

/* Hover and focus states */
.inputContent:hover,
.inputContent1:hover,
.inputContent3:hover,
.inputContent4:hover,
.titleDropdown:hover,
.genderDropdown:hover,
.countryCodeSelect:hover,
.phoneInput:hover,
.fileInputLabel:hover {
	border-color: #999999;
}

.inputContent:focus,
.inputContent1:focus,
.inputContent3:focus,
.inputContent4:focus,
.titleDropdown:focus,
.genderDropdown:focus,
.countryCodeSelect:focus,
.phoneInput:focus {
	outline: none;
	border-color: #1e70bb;
}

/* Error styles */
.errorText {
	color: #dc3545;
	font-size: 12px;
	margin-top: 4px;
}

/* Button container */
.buttoncontainer {
	display: flex;
	justify-content: flex-end;
	gap: 12px;
	margin-top: 32px;
	padding-top: 16px;
	border-top: 1px solid #E6E6E6;
}

/* Button styles */
.cancelButton,
.continueButton {
	display: flex;
	padding: 8px 24px;
	justify-content: center;
	align-items: center;
	border-radius: 4px;
	font-size: 14px;
	font-weight: 600;
	line-height: 24px;
	cursor: pointer;
	min-width: 120px;
	transition: all 0.2s ease-in-out;
}

.cancelButton {
	background: #FFFFFF;
	border: 1px solid #E6E6E6;
	color: #1e70bb;
}

.cancelButton:hover {
	background: #F5F5F5;
	border-color: #CCCCCC;
}

.continueButton {
	background: #1e70bb;
	border: 1px solid #1e70bb;
	color: #FFFFFF;
}

.continueButton:hover {
	background: #1e70bb;
	border-color: #1e70bb;
}

.label {
	font-weight: 600;
	font-size: 14px;
	line-height: 24px;
}
.primaryButton, .secondaryButton {
	width: 180px;
	border-radius: 12px;
	padding: 17px 16px;
	font-size: 20px;
	font-family: Inter;
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	transition: background-color 0.2s;
}

.fileUploadContainer {
	margin: 1rem 0;
}

.fileInputLabel {
	display: inline-block;
	padding: 8px 16px;
	background-color: #f0f0f0;
	border: 1px solid #ddd;
	border-radius: 4px;
	cursor: pointer;
	transition: background-color 0.3s;
}

.fileInputLabel:hover {
	background-color: #e0e0e0;
}

.fileName {
	margin-top: 8px;
	font-size: 0.9rem;
	color: #666;
	display: flex;
	align-items: center;
	gap: 8px;
}

.removeFile {
	background: none;
	border: none;
	color: #ff4444;
	cursor: pointer;
	padding: 4px 8px;
	font-size: 1rem;
	border-radius: 50%;
}

.removeFile:hover {
	background-color: #ffeeee;
}

.errorText {
	color: #ff4444;
	font-size: 0.8rem;
	margin-top: 4px;
}

.customSelect {
	position: relative;
	width: 100px;
}

.selectTrigger {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px 12px;
	border: 1px solid #ccc;
	border-radius: 4px;
	background: white;
	cursor: pointer;
	height: 40px;
}

.arrow {
	margin-left: auto;
	font-size: 10px;
	color: #666;
}

.optionsList {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	margin-top: 4px;
	background: white;
	border: 1px solid #ccc;
	border-radius: 4px;
	max-height: 200px;
	overflow-y: auto;
	z-index: 1000;
	box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.option {
	display: flex;
	align-items: center;
	gap: 8px;
	padding: 8px 12px;
	cursor: pointer;
	transition: background-color 0.2s;
}

.option:hover {
	background-color: #f5f5f5;
}

.flagIcon {
	width: 24px;
	height: 16px;
	object-fit: cover;
}

.phoneInputGroup {
	display: flex;
	gap: 10px;
	align-items: center;
}

.phoneInput {
	flex: 1;
	height: 40px;
	padding: 8px;
	border: 1px solid #ccc;
	border-radius: 4px;
}

/* Add custom scrollbar styles for the dropdown */
.optionsList::-webkit-scrollbar {
	width: 8px;
}

.optionsList::-webkit-scrollbar-track {
	background: #f1f1f1;
}

.optionsList::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 4px;
}

.optionsList::-webkit-scrollbar-thumb:hover {
	background: #555;
}

.buttoncontainer {
	display: flex;
	justify-content: flex-end;
	gap: 16px;
	margin-top: 24px;
}

.errorMessage {
	color: #dc3545;
	background-color: #f8d7da;
	border: 1px solid #f5c6cb;
	border-radius: 4px;
	padding: 12px;
	margin-bottom: 20px;
	text-align: center;
}

.pageLayout {
	display: flex;
	flex: 1;
	/* margin-top: 64px; */
	padding: 20px;
	gap: 20px;
}

.dialogOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  backdrop-filter: blur(4px);
}

.dialogContent {
  background-color: white;
  padding: 2.5rem;
  border-radius: 16px;
  box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
  max-width: 450px;
  width: 90%;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  animation: slideDown 0.3s ease-out;
}

.dialogContent h2 {
  color: #1e70bb;
  font-size: 28px;
  margin-bottom: 12px;
  font-weight: 600;
}

.dialogContent p {
  color: #666;
  font-size: 16px;
  margin-bottom: 24px;
  line-height: 1.5;
}

.dialogButtons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin-top: 2rem;
}

.dialogButton {
  padding: 0.875rem 1.75rem;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  font-size: 16px;
  transition: all 0.2s ease;
  min-width: 160px;
}

.dialogButton:first-child {
  background-color: #1e70bb;
  color: white;
  box-shadow: 0 2px 8px rgba(30, 112, 187, 0.3);
}

.dialogButton:last-child {
  background-color: #f8f9fa;
  color: #1e70bb;
  border: 2px solid #1e70bb;
}

.dialogButton:first-child:hover {
  background-color: #1859a3;
  transform: translateY(-1px);
  box-shadow: 0 4px 12px rgba(30, 112, 187, 0.4);
}

.dialogButton:last-child:hover {
  background-color: #e9ecef;
  transform: translateY(-1px);
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 300px;
  padding: 2rem;
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #004788;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1.5rem;
}

.loadingText {
  font-size: 18px;
  color: #004788;
  font-weight: 500;
  margin-bottom: 0.5rem;
}

.loadingSubtext {
  font-size: 14px;
  color: #64748b;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}