.button {
    display: flex;
    padding: 8px 24px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    cursor: pointer;
    min-width: 120px;
    transition: all 0.2s ease-in-out;
  }
  
  .button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  
  /* .primary {
    background: #1e70bb;
    border: 1px solid #1e70bb;
    color: #FFFFFF;
  } */
  
  .primary:hover:not(:disabled) {
    background: #1857a4;
    border-color: #1857a4;
  }
  .primary, .secondary {
    width: 180px;
    border-radius: 12px;
    padding: 17px 16px;
    font-size: 20px;
    font-family: Inter;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: background-color 0.2s;
  }
  .primary {
    background-color: #1e70bb;
    color: #fff;
    border: none;
  }
  
  .secondary {
    background-color: #fff;
    color: #1e70bb;
    border: 2px solid #1e70bb;
  }
  /* .secondary {
    background: #FFFFFF;
    border: 1px solid #E6E6E6;
    color: #1e70bb;
  }
   */
  .secondary:hover:not(:disabled) {
    background: #F5F5F5;
    border-color: #CCCCCC;
  }
  
  /* You can add more variants as needed */
  .danger {
    background: #dc3545;
    border: 1px solid #dc3545;
    color: #FFFFFF;
  }
  
  .danger:hover:not(:disabled) {
    background: #c82333;
    border-color: #c82333;
  }