@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=JetBrains+Mono:wght@400;500&display=swap');
/* Font configuration */
:root {
  --font-primary: 'Inter', -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, sans-serif;
  --font-secondary: 'DM Sans', 'Inter', -apple-system, BlinkMacSystemFont, sans-serif;
  --font-mono: 'JetBrains Mono', 'Roboto Mono', 'Courier New', monospace;
}

/* Base typography */
.container {
  min-height: 100vh;
  background-color: #f8fafc;
  font-family: var(--font-primary);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  display: flex;
  padding: 20px;
  gap: 20px;
}
.overallProgress {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 24px;
  width: 100%;
}

.progressInfo {
  display: flex;
  align-items: center;
  min-width: 200px;
}

.progressLabel {
  font-family: var(--font-secondary);
  font-size: clamp(14px, 1.8vw, 16px);
  font-weight: 500;
  color: #64748b;
  margin-right: 8px;
}

.progressPercentage {
  font-family: var(--font-secondary);
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 600;
  color: #1e70bb;
  background: rgba(30, 112, 187, 0.1);
  padding: 4px 8px;
  border-radius: 6px;
}
.progressBarWrapper {
  flex: 1;
  margin: 0;
}
.main {
  flex: 1;
  padding: 20px;
  overflow-x: auto;
}

/* Headings */
.title {
  font-family: var(--font-secondary);
  font-size: clamp(24px, 3vw, 32px);
  font-weight: 600;
  letter-spacing: -0.02em;
  margin-bottom: 30px;
  color: #1a1a1a;
}

.statusBoxes {
  display: inline-flex;
  gap: 24px;
  padding: 20px 0;
  min-width: min-content;
}

.statusBox {
  width: 320px;
  flex-shrink: 0;
  background: #ffffff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  position: relative;
}

.statusBox:not(:last-child)::after {
  content: '→';
  position: absolute;
  right: -20px;
  top: 50%;
  transform: translateY(-50%);
  font-size: 20px;
  color: #004788;
  z-index: 1;
}

.boxHeader {
  background: #004788;
  margin: -20px -20px 20px -20px;
  padding: 20px;
  border-radius: 12px 12px 0 0;
  color: white;
}

.boxHeader h2 {
  font-family: var(--font-secondary);
  font-size: clamp(16px, 2vw, 18px);
  font-weight: 600;
  letter-spacing: -0.01em;
  margin: 0 0 15px 0;
}

.progressBar {
  height: 12px;
  background: rgba(253, 255, 253, 0.222);
  border-radius: 6px;
  border-color: #004788;
  border-style: solid;
  border-width: 1px;
  position: relative;
  overflow: hidden;
  box-shadow: inset 0 1px 2px rgba(248, 247, 247, 0.1);
}

.progressText {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  color: #ffffff;
  font-size: 11px;
  font-weight: 600;
  z-index: 1;
  text-shadow: 0 1px 1px rgba(18, 18, 18, 0.2);
}

.progressFill {
  height: 100%;
  background: linear-gradient(90deg, #1e70bb, #2983d5);
  border-radius: 6px;
  width: 0%;
  transition: width 0.5s ease;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
}

.itemList {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  background: #f8fafc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.2s ease;
  text-decoration: none;
  color: #1f2937;
  border: 1px solid transparent;
  font-family: var(--font-primary);
  font-size: clamp(14px, 1.5vw, 16px);
  font-weight: 500;
}

.item:hover {
  background: #f1f5f9;
  border-color: #004788;
  color: #004788;
}

.itemTime {
  font-family: var(--font-mono);
  font-size: clamp(12px, 1.2vw, 14px);
  font-weight: 500;
  color: #6b7280;
}

.dropdownHeader {
  cursor: pointer;
}

.arrow {
  display: inline-block;
  margin-left: 8px;
  font-size: 12px;
  transition: transform 0.2s ease;
  color: #004788;
}

.arrow.expanded {
  transform: rotate(180deg);
}

.subItems {
  margin-left: 20px;
  margin-top: 4px;
}

.subItem {
  background: #ffffff;
  margin-top: 4px;
  border-left: 2px solid #004788;
}

.subItem:hover {
  background: #f1f5f9;
  color: #004788;
}

.main::-webkit-scrollbar {
  height: 8px;
}

.main::-webkit-scrollbar-track {
  background: #f1f5f9;
  border-radius: 4px;
}

.main::-webkit-scrollbar-thumb {
  background: #004788;
  border-radius: 4px;
  opacity: 0.8;
}

.main::-webkit-scrollbar-thumb:hover {
  background: #004788;
}

/* Resources Section */
.resourcesSection {
  margin-top: 40px;
  padding: 20px 0;
}

.resourcesTitle {
  font-family: var(--font-secondary);
  font-size: clamp(20px, 2.5vw, 24px);
  font-weight: 600;
  letter-spacing: -0.02em;
  color: #1a1a1a;
  margin-bottom: 24px;
}

.resourcesGrid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
  max-width: 100%;
}

.resourceCard {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 24px;
  background: #ffffff;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  text-decoration: none;
  color: #1a1a1a;
  font-family: var(--font-primary);
  font-size: clamp(14px, 1.5vw, 16px);
  font-weight: 500;
  letter-spacing: -0.01em;
  transition: all 0.2s ease;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.resourceCard:hover {
  border-color: #004788;
  background: #f8fafc;
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.resourceCard span {
  flex: 1;
  margin-right: 16px;
}

.resourceArrow {
  color: #004788;
  font-size: 14px;
  opacity: 0.8;
  transition: transform 0.2s ease;
}

.resourceCard:hover .resourceArrow {
  transform: translateX(4px);
  opacity: 1;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .resourcesGrid {
    grid-template-columns: 1fr;
  }

  .resourceCard {
    padding: 16px 20px;
  }

  .resourcesTitle {
    font-size: 20px;
    margin-bottom: 16px;
  }
}

/* Ensure proper spacing when scrolling horizontally */
@media (max-width: 1200px) {
  .resourcesSection {
    min-width: min-content;
    padding-right: 20px;
  }
}

/* Dark mode support */
/* @media (prefers-color-scheme: dark) {
  .resourceCard {
    background: #1f2937;
    border-color: #374151;
    color: #f3f4f6;
  }

  .resourceCard:hover {
    background: #374151;
    border-color: #60a5fa;
  }

  .resourcesTitle {
    color: #f3f4f6;
  }
} */

/* High contrast mode */
@media (forced-colors: active) {
  .resourceCard {
    border: 2px solid ButtonText;
  }

  .resourceCard:hover {
    border-color: Highlight;
  }
}

.settingsContainer {
  max-width: 800px;
  margin: 0 auto;
}

.settingsSection {
  background: #ffffff;
  border-radius: 12px;
  padding: 24px;
  margin-bottom: 24px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.sectionTitle {
  font-family: var(--font-secondary);
  font-size: 18px;
  font-weight: 600;
  color: #1a1a1a;
  margin-bottom: 20px;
}

.settingsGrid {
  display: grid;
  gap: 20px;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
}

.settingsItem {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.settingsItem label {
  font-size: 14px;
  font-weight: 500;
  color: #64748b;
}

.settingsValue {
  font-size: 16px;
  color: #1f2937;
  font-weight: 500;
}

.verifiedBadge {
  background: #dcfce7;
  color: #166534;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}

.unverifiedBadge {
  background: #fee2e2;
  color: #991b1b;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 14px;
  font-weight: 500;
  display: inline-block;
}

.settingsActions {
  display: flex;
  gap: 16px;
}

.actionButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 10px 16px;
  background: #f8fafc;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  color: #1f2937;
  text-decoration: none;
  font-weight: 500;
  transition: all 0.2s ease;
}

.actionButton:hover {
  background: #f1f5f9;
  border-color: #004788;
  color: #004788;
}

.actionArrow {
  font-size: 16px;
  transition: transform 0.2s ease;
}

.actionButton:hover .actionArrow {
  transform: translateX(4px);
}

@media (max-width: 640px) {
  .settingsGrid {
    grid-template-columns: 1fr;
  }
  
  .settingsSection {
    padding: 16px;
  }
}
.passwordForm {
    max-width: 400px;
    margin-top: 20px;
  }
  
  .passwordField {
    margin-bottom: 20px;
  }
  
  .passwordField label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #64748b;
    font-weight: 500;
  }
  
  .passwordWrapper {
    position: relative;
    display: flex;
    align-items: center;
  }
  
  .passwordWrapper input {
    width: 100%;
    padding: 10px 40px 10px 12px;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    font-size: 14px;
    color: #1f2937;
  }
  
  .togglePassword {
    position: absolute;
    right: 12px;
    background: none;
    border: none;
    color: #64748b;
    cursor: pointer;
    padding: 0;
  }
  
  .errorText {
    color: #dc2626;
    font-size: 14px;
    margin-top: 8px;
  }
  
  .formActions {
    display: flex;
    gap: 12px;
    margin-top: 24px;
  }
  
  .cancelButton {
    padding: 8px 16px;
    background: #f1f5f9;
    border: 1px solid #e2e8f0;
    border-radius: 6px;
    color: #64748b;
    font-weight: 500;
    cursor: pointer;
  }
  
  .submitButton {
    padding: 8px 16px;
    background: #004788;
    border: none;
    border-radius: 6px;
    color: white;
    font-weight: 500;
    cursor: pointer;
  }
  
  .submitButton:disabled {
    background: #94a3b8;
    cursor: not-allowed;
  }