.stepper {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  text-align: right;
  font-size: 14px;
  color: #1e70bb;
  margin-bottom: 32px;
}

.step {
  font-weight: 500;
}

.totalSteps {
  color: #83898c;
}

.barcontainer {
  align-self: stretch;
  position: relative;
  height: 12px;
  width: 100%;
  background-color: #f5f5f5;
  border: 1px solid #e0e0e0;
  border-radius: 20px;
  padding: 2px;
  cursor: pointer;
}

.bg {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  border-radius: 20px;
  background-color: #e2e6f9;
  height: 6px;
}

.progressSections {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  z-index: 2;
}

.progressSection {
  height: 100%;
  position: relative;
  transition: background-color 0.3s ease;
}

.progressSection:not(:last-child)::after {
  content: '';
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 2px;
  height: 8px;
  background-color: #e0e0e0;
  z-index: 3;
}

.progressSection.clickable {
  cursor: pointer;
}

.progressSection.clickable:hover {
  background-color: rgba(30, 112, 187, 0.1);
}

.progressSection.completed {
  background-color: transparent;
}

.bar {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  border-radius: 20px;
  background-color: #1e70bb;
  height: 8px;
  transition: width 0.3s ease;
  z-index: 1;
}
