.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 48px;
    width: 100%;
    max-width: 1440px;
    margin: 0 auto;
    background: #FFFFFF;
    height: 64px;
}

.header__logo {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logoIcon {
    width: 38px;
    height: 27px;
}

.logoText {
    font-family: 'DM Mono', monospace;
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    color: #000000;
}

.header__buttons {
    display: flex;
    gap: 12px;
    align-items: center;
}

.header__login {
    padding: 8px 20px;
    height: 38px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    background: transparent;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 6px;
    color: #333333;
}

.header__signup {
    padding: 8px 20px;
    height: 38px;
    font-family: 'DM Sans', sans-serif;
    font-size: 15px;
    font-weight: 600;
    background: #000000;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.2s ease;
    color: #FFFFFF;
}

.header__login:hover {
    background: #d3e3f4;
}

.header__signup:hover {
    background: #333333;
    transform: translateY(-1px);
}

@media (max-width: 768px) {
    .header {
        padding: 10px 16px;
        height: 56px;
    }
    
    .logoText {
        font-size: 20px;
    }
    
    .header__login,
    .header__signup {
        height: 34px;
        padding: 6px 16px;
        font-size: 14px;
    }
}