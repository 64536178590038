.container {
    min-height: 100vh;
    background-color: #f8f9fa;
  }
  
  .pageLayout {
    display: flex;
    padding: 20px;
    gap: 20px;
  }
  
  .mainContent {
    flex: 1;
    padding: 30px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  }
  
  .loadingContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 60px 0;
  }
  
  .loadingSpinner {
    width: 48px;
    height: 48px;
    border: 4px solid #e2e8f0;
    border-top: 4px solid #004788;
    border-radius: 50%;
    animation: spin 1s linear infinite;
    margin-bottom: 20px;
  }
  
  .loadingText {
    font-size: 20px;
    color: #004788;
    margin: 0;
  }
  
  .successContainer,
  .errorContainer,
  .processingContainer {
    text-align: center;
    padding: 80px 40px;
    max-width: 520px;
    margin: 40px auto;
    border-radius: 24px;
    background: white;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
    position: relative;
    overflow: hidden;
  }
  
  .successContainer::before,
  .errorContainer::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 6px;
    background: linear-gradient(90deg, 
      var(--status-color-start), 
      var(--status-color-end)
    );
  }
  
  .successContainer {
    --status-color-start: #48bb78;
    --status-color-end: #38a169;
  }
  
  .errorContainer {
    --status-color-start: #e53e3e;
    --status-color-end: #c53030;
  }
  
  .checkmark,
  .errorIcon {
    width: 120px;
    height: 120px;
    border-radius: 50%;
    color: white;
    font-size: 56px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto 40px;
    position: relative;
    animation: scaleIn 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  }
  
  .checkmark {
    background: linear-gradient(135deg, #48bb78, #38a169);
    box-shadow: 0 12px 24px rgba(72, 187, 120, 0.25);
  }
  
  .checkmark::after {
    content: '✓';
    position: relative;
    top: -2px;
  }
  
  .errorIcon {
    background: linear-gradient(135deg, #e53e3e, #c53030);
    box-shadow: 0 12px 24px rgba(229, 62, 62, 0.25);
  }
  
  .errorIcon::after {
    content: '✕';
    position: relative;
    top: -2px;
  }
  
  .successContainer h2,
  .errorContainer h2 {
    color: #1a202c;
    margin-bottom: 20px;
    font-size: 36px;
    font-weight: 700;
    animation: slideUp 0.5s ease-out 0.3s both;
  }
  
  .successContainer p,
  .errorContainer p {
    color: #4a5568;
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 1.6;
    animation: slideUp 0.5s ease-out 0.4s both;
  }
  
  .returnButton,
  .retryButton {
    margin-top: 32px;
    padding: 18px 36px !important;
    font-size: 18px !important;
    font-weight: 600 !important;
    background: linear-gradient(135deg, #004788, #003366) !important;
    color: white !important;
    border: none !important;
    border-radius: 16px !important;
    cursor: pointer;
    transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 0 4px 12px rgba(0, 71, 136, 0.2) !important;
    animation: slideUp 0.5s ease-out 0.5s both;
    min-width: 200px;
  }
  
  .returnButton:hover,
  .retryButton:hover {
    transform: translateY(-2px);
    box-shadow: 0 8px 20px rgba(0, 71, 136, 0.3) !important;
    background: linear-gradient(135deg, #003366, #002244) !important;
  }
  
  @keyframes scaleIn {
    0% {
      transform: scale(0.6);
      opacity: 0;
    }
    100% {
      transform: scale(1);
      opacity: 1;
    }
  }
  
  @keyframes slideUp {
    0% {
      transform: translateY(20px);
      opacity: 0;
    }
    100% {
      transform: translateY(0);
      opacity: 1;
    }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }