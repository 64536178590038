.container {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #f8fafc;
  }
  
  .pageLayout {
    display: flex;
    padding: 20px;
    gap: 20px;
  }
  
  .mainContent {
    flex: 1;
    background-color: #ffffff00;
    border-radius: 12px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  
  .wrapper {
  position: relative;
    flex: 1;
    padding: 35px 24px;
    height: calc(100vh - 112px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
  }
  
  .title {
    position: relative;
    top: 10px;
    left: 0%;
    font-size: 30px;
    line-height: 25px;
    font-weight: 500;
    font-family: Roboto;
    color: #004788;
    margin-bottom: 32px;
    padding-left: 24px;
  }
  .rfeAnalysisCard {
    min-width: 575px;
  background-color: #fff;
  border: 1px solid #dde2e5;
  border-radius: 20px;
  padding: 37px 43px;
  margin-bottom: 32px;
  position: relative;
  top: 20px;
  }
  .rfeAnalysisHeader {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 16px;
    border-bottom: 1px solid #dde2e5;
  }
  .rfeAnalysisContent {
    padding-top: 24px;
  }
  .headerLeft {
    display: flex;
    align-items: center;
    gap: 12px;
    font-size: 16px;
    line-height: 24px;
    color: #1a1a1a;
    font-family: 'Open Sans', sans-serif;
  }
  .aiIcon {
    font-size: 20px;
  }
  .collapseButton {
    background: none;
    border: none;
    cursor: pointer;
    color: #6B7280;
  }
  .uploadSection {
    display: flex;
    flex-direction: column;
    gap: 16px;
    padding-top: 24px;
    padding-bottom: 24px;
    border-bottom: 1px solid #dde2e5;
    margin-bottom: 24px;
    margin-top: 24px;
    border-top: 1px solid #dde2e5;
    padding-left: 24px;
    padding-right: 24px;
    color: #004788;
  }
  .uploadBox {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
  .generateButton {
    background-color: #004788;
    color: #fff;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    cursor: pointer;
  }
  .rfeContent {
    padding: 20px;
    font-family: 'Open Sans', sans-serif;
  }
  
  .stepsList {
    margin: 20px 0;
    padding-left: 20px;
    font-family: 'Open Sans', sans-serif;
  }
  
  .stepsList li {
    margin: 10px 0;
    color: #333;
    font-family: 'Open Sans', sans-serif;
  }
  
  .startButton {
    background-color: #004788;
    color: white;
    border: none;
    padding: 12px 24px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    /* margin-top: 20px; */
    transition: background-color 0.3s ease;
    font-family: 'Open Sans', sans-serif;
  }
  
  .startButton:hover {
    background-color: #0056b3;
  }
.rfeAnalysis {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
}
.uploadBox {
  margin-bottom: 24px;

}
.uploadInput {
  margin-top: 10px;
}
.heading {
  padding-left: 24px;
  padding-right: 24px;
  padding-top: 24px;
  padding-bottom: 24px;
  font-family: 'Open Sans', sans-serif;
}

.h2 {
  font-family: 'Open Sans', sans-serif;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  font-weight: 600;
  margin-bottom: 16px;
  margin-top: 16px;
}
.text {
  font-family: 'Open Sans', sans-serif;
  font-size: 20px;
  line-height: 24px;
  color: #1a1a1a;
  font-weight: 400;
  margin-top: 16px;
  margin-bottom: 16px;
  
}   