.verificationSuccess {
    min-height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #f5f7f9;
    padding: 20px;
    font-family: sans-serif;

  }
  
  .card {
    background: white;
    border-radius: 20px;
    padding: 40px;
    text-align: center;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
    max-width: 500px;
    width: 90%;
    font-family: sans-serif;

  }
  
  .logo {
    width: 185px;
    margin-bottom: 30px;
  }
  
  .iconContainer {
    margin: 30px 0;
    color: #4CAF50;
    font-size: 64px;
  }
  
  .heading {
    font-size: 32px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
    white-space: nowrap;
    /* font-family: sans-serif; */
  }
  
  .message {
    font-size: 16px;
    line-height: 1.6;
    color: #546e7a;
    margin-bottom: 30px;
    font-family: sans-serif;
  }
  
  .loginButton {
    display: inline-block;
    background: #3c5a99;
    color: white;
    padding: 15px 30px;
    border-radius: 8px;
    text-decoration: none;
    font-weight: 500;
    transition: background-color 0.2s;
    font-family: sans-serif;
  }
  
  .loginButton:hover {
    background: #2e497a;
  }