.inputWrapper {
  flex: 1;
  position: relative;
}

.inputField {
  width: 100%;
  padding: 12px;
  font-size: 16px;
  color: #6c757d;
  background-color: rgba(239, 241, 249, 0.6);
  border: 1px solid #ced4da;
  border-radius: 8px;
  box-sizing: border-box;
  transition: all 0.2s ease-in-out;
}

.inputField:hover {
  border-color: #999999;
}

.inputField:focus {
  outline: none;
  border-color: #1e70bb;
  background-color: #ffffff;
}

.errorInput {
  border-color: #ff4d4f;
}

.errorMessage {
  color: #ff4d4f;
  font-size: 12px;
  position: absolute;
  bottom: -20px;
  left: 0;
}

.phoneInputGroup {
  display: flex;
  gap: 10px;
  align-items: center;
}

.phoneInput {
  flex: 1;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
} 