.verificationContainer {
  max-width: 600px;
  margin: 80px auto;
  padding: 40px;
  text-align: center;
  border-radius: 20px;
  background: white;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.08);
}

.iconContainer {
  margin-bottom: 25px;
}

.iconContainer i {
  font-size: 48px;
  color: #4CAF50;
}

.heading {
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 20px;
  color: #2c3e50;
}

.divider {
  height: 3px;
  width: 60px;
  background: linear-gradient(to right, #4CAF50, #81C784);
  margin: 20px auto;
  border-radius: 2px;
}

.message {
  font-size: 16px;
  line-height: 1.6;
  color: #546e7a;
  margin-bottom: 25px;
  padding: 0 20px;
}

.note {
  font-size: 15px;
  color: #78909c;
  background: #f5f7f9;
  padding: 15px;
  border-radius: 10px;
  margin-top: 20px;
}

.resendLink {
  color: #4CAF50;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.2s ease;
}

.resendLink:hover {
  color: #388E3C;
  text-decoration: underline;
}
  