/* ... existing styles ... */

.header {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.backButton {
  background: none;
  border: none;
  font-size: 20px;
  color: #666;
  cursor: pointer;
  padding: 8px;
  margin-right: 16px;
}

.formTitle {
  font-size: 24px;
  margin: 0;
}

.formTabs {
  display: flex;
  gap: 1rem;
  margin-bottom: 2rem;
}

.tab {
  padding: 0.75rem 1.5rem;
  border: none;
  background: none;
  cursor: pointer;
  font-size: 16px;

  border-bottom: 2px solid transparent;
}

.activeTab {
  border-bottom: 2px solid #007bff;
  color: #007bff;
  font-weight: 500;
  font-family: sans-serif;
  font-size: 16px;
}

.section {
  margin-bottom: 32px;
}



.formGroup {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.formRow {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.formRowTriple {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
  margin-bottom: 20px;
}

.formRowSingle {
  display: grid;
  grid-template-columns: 1fr;
  gap: 20px;
  margin-bottom: 20px;
}

.formField {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 15px;
}

.formField label {
  font-size: 14px;
  color: #64748b;
  font-weight: 500;
  margin-bottom: 4px;
}

.formField input,
.formField select,
.formField textarea {
  padding: 12px 16px;
  border: 1px solid #e2e8f0;
  border-radius: 10px;
  font-size: 14px;
  transition: all 0.2s ease;
  background-color: #f8fafc;
  color: #334155;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}

.formField input:focus,
.formField select:focus,
.formField textarea:focus {
  outline: none;
  border-color: #3b82f6;
  box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
  background-color: #ffffff;
}

.formField input:hover,
.formField select:hover,
.formField textarea:hover {
  border-color: #3b82f6;
  background-color: #ffffff;
}

.radioGroup {
  display: flex;
  flex-direction: column;
  gap: 8px;
  padding: 8px 0;
}

.radioGroup label {
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  padding: 8px 12px;
  border-radius: 6px;
  transition: background-color 0.2s ease;
}

.radioGroup label:hover {
  background-color: rgba(239, 241, 249, 0.6);
}
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8fafc;
}

.pageLayout {
  display: flex;
  flex: 1;
  padding: 20px;
  gap: 20px;
}

.createProfile {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  font-family: 'Open Sans', sans-serif;
  flex: 1;
  background-color: #ffffff00;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.wrapper {
  flex: 1;
  width: 100%;
  padding: 35px 24px;
  height: 100vh;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.createYourProfile {
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  font-family: Roboto;
  color: #004788;
  margin-bottom: 32px;
  padding-left: 24px;
}

.formcontainer {
  max-width: 1200px;
  position: relative;
  margin: 0 auto;
  padding: 0px;
  display: flex;
  flex-direction: row;
  gap: 24px;
}
.mainContent {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 100%;
}

.formSection {
  flex: 1;
  min-width: unset;
  background-color: #fff;
  border: 1px solid #dde2e5;
  border-radius: 20px;
  padding: 24px;
  height: fit-content;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
.twoColumnLayout {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
}

.title {
  position: relative;
  top: 10px;
  left: 0%;
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  font-family: Roboto;
  color: #004788;
  margin-bottom: 32px;
  padding-left: 24px;
}
.sectionTitle {
  font-size: 18px;
  margin-bottom: 16px;
  color: #004788;
  font-family: sans-serif;
}
.pronounDropdown {
width: 100%;
height: 35px;
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
}
/* Phone input group */
.phoneInputGroup {
display: flex;
gap: 12px;
width: 100%;
margin-bottom: 10px;
}
.phoneInput {
flex: 1;
height: 45px;
padding: 12px 16px;
border: 1px solid #e2e8f0;
border-radius: 10px;
font-size: 14px;
background-color: #f8fafc;
color: #334155;
box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
}
.phoneInput:focus {
outline: none;
border-color: #3b82f6;
box-shadow: 0 0 0 3px rgba(59, 130, 246, 0.1);
background-color: #ffffff;
}
.phoneInput {
flex: 1;
height: 40px;
padding: 8px;
border: 1px solid #ccc;
border-radius: 4px;
}
.customSelect {
position: relative;
width: 100px;
}
.selectTrigger {
display: flex;
align-items: center;
gap: 8px;
padding: 8px 12px;
border: 1px solid #ccc;
border-radius: 4px;
background: white;
cursor: pointer;
height: 40px;
}
.arrow {
margin-left: auto;
font-size: 10px;
color: #666;
}
.optionsList {
position: absolute;
top: 100%;
left: 0;
right: 0;
margin-top: 4px;
background: white;
border: 1px solid #ccc;
border-radius: 4px;
max-height: 200px;
overflow-y: auto;
z-index: 1000;
box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}
.option {
display: flex;
align-items: center;
gap: 8px;
padding: 8px 12px;
cursor: pointer;
transition: background-color 0.2s;
}

.option:hover {
background-color: #f5f5f5;
}
.flagIcon {
width: 24px;
height: 16px;
object-fit: cover;
}
/* Add custom scrollbar styles for the dropdown */
.optionsList::-webkit-scrollbar {
width: 8px;
}

.optionsList::-webkit-scrollbar-track {
background: #f1f1f1;
}

.optionsList::-webkit-scrollbar-thumb {
background: #888;
border-radius: 4px;
}

.optionsList::-webkit-scrollbar-thumb:hover {
background: #555;
}
.buttoncontainer {
display: flex;
justify-content: flex-end;
gap: 16px;
margin-top: 24px;
}

.letterGenerationForm {
display: grid;
grid-template-columns: 1fr 1fr;
gap: 2rem;
padding: 2rem;
}

.letterConfigSection {
display: flex;
flex-direction: column;
gap: 2rem;
}

.letterPreviewSection {
background-color: #ffffff;
border-radius: 12px;
border: 1px solid #e2e8f0;
padding: 24px;
}

.letterPreviewTitle {
color: #004788;
font-size: 24px;
font-weight: 600;
margin-bottom: 16px;
font-family: sans-serif;
}

.letterPreviewContent {
  width: 100%;
  min-height: 500px;
  max-height: calc(100vh - 200px); /* Account for header/footer */
  overflow: auto;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 20px;
  margin-top: 20px;
}

.previewContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
  height: 100%;
  overflow: auto;
  padding: 16px;
}

.letterPreview {
  width: 100%;
  height: 100%;
  min-height: 600px;
  border: 1px solid #e6e6e6;
  border-radius: 4px;
  aspect-ratio: 8.5 / 11; /* Standard letter size aspect ratio */
  object-fit: contain;
}

.pdfFallback {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 200px;
  background-color: #f5f5f5;
  border-radius: 4px;
  padding: 20px;
  text-align: center;
}

.previewActions {
  display: flex;
  gap: 12px;
  justify-content: center;
}

.downloadButton,
.newTabButton,
.openNewTabButton {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.downloadButton {
  background-color: #1e70bb;
  color: white;
}

.newTabButton {
  background-color: #f5f5f5;
  color: #333;
}

.openNewTabButton {
  background-color: #1e70bb;
  color: white;
  margin-top: 12px;
}

.downloadButton:hover,
.openNewTabButton:hover {
  background-color: #165999;
}

.newTabButton:hover {
  background-color: #e6e6e6;
}

.downloadIcon,
.newTabIcon {
  font-size: 16px;
}

.emptyPreview {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  color: #666;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;  /* Ensure consistent height */
  padding: 40px;
}

.loadingSpinner {
  width: 60px;  /* Slightly larger spinner */
  height: 60px;
  border: 4px solid #e6e6e6;
  border-top: 4px solid #1e70bb;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 32px;  /* More space between spinner and text */
}

.loadingMessage {
  text-align: center;
}

.loadingMessage h4 {
  color: #1e70bb;
  font-size: 24px;  /* Larger font size */
  margin: 0 0 16px 0;
  font-weight: 500;
  font-family: sans-serif;
}

.loadingMessage p {
  color: #64748b;  /* Softer text color */
  font-size: 16px;
  margin: 0;
  line-height: 1.5;
  font-family: sans-serif;
}

.loadingSubtext {
  color: #718096 !important;
  font-size: 14px !important;
  margin-top: 16px !important;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.letterSection, .previewSection {
background: #fff;
padding: 1.5rem;
border-radius: 8px;
font-family: sans-serif;
color: #004788;
font-size: 16px;
}

.configureText {
color: #1e70bb;
margin: 1rem 0;
}

.endorsementSection {
  margin: 24px 0;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  border-radius: 16px;
  border: 1px solid #e2e8f0;
  max-height: 600px;
  overflow-y: auto;
  box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
  position: relative;
}

.sectionHeader {
  position: sticky;
  top: -30px;
  background-color: #ffffff;
  padding: 24px 32px 12px;
  margin: 0;
  z-index: 10;
  border-bottom: 2px solid #e2e8f0;
}

.letterSection {
  padding: 0 32px 32px;
}

.itemsList {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-top: 8px;
}

.goalCheckbox {
display: flex;
align-items: flex-start;
gap: 16px;
padding: 20px;
border: 1px solid #e2e8f0;
border-radius: 12px;
background-color: #ffffff;
transition: all 0.2s ease;
cursor: pointer;
}

.goalCheckbox:hover {
border-color: #3b82f6;
background-color: #f8fafc;
box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1);
transform: translateY(-1px);
}

.goalCheckbox input[type="checkbox"] {
width: 24px;
height: 24px;
margin-top: 4px;
cursor: pointer;
accent-color: #3b82f6;
border-radius: 6px;
}

.goalCheckbox label {
flex: 1;
cursor: pointer;
}

.itemTitle {
display: block;
font-weight: 600;
margin-bottom: 8px;
color: #1a365d;
font-size: 16px;
font-family: 'Inter', sans-serif;
}

.itemDescription {
font-size: 14px;
line-height: 1.5;
color: #4a5568;
font-family: 'Inter', sans-serif;
}

.goalCheckbox:has(input:checked) {
border-color: #3b82f6;
background-color: #ebf5ff;
}

/* Custom scrollbar styles */
.endorsementSection::-webkit-scrollbar {
width: 8px;
}

.endorsementSection::-webkit-scrollbar-track {
background: #f1f5f9;
border-radius: 4px;
}

.endorsementSection::-webkit-scrollbar-thumb {
background: #cbd5e1;
border-radius: 4px;
}

.endorsementSection::-webkit-scrollbar-thumb:hover {
background: #94a3b8;
}

.helperText {
color: #1e70bb;
font-size: 0.9rem;
margin: 0.5rem 0;
}

.addButton {
width: 100%;
padding: 0.75rem;
border: 1px dashed #ccc;
background: transparent;
border-radius: 4px;
cursor: pointer;
margin: 0.5rem 0;
}

.generateButton {
width: 100%;
padding: 16px 32px;
margin-top: 24px;
font-size: 16px;
font-weight: 600;
color: white;
background: linear-gradient(135deg, #bad0f3 0%, #2563eb 100%);
border: none;
border-radius: 8px;
cursor: pointer;
transition: all 0.3s ease;
box-shadow: 0 4px 6px -1px rgba(59, 130, 246, 0.2), 0 2px 4px -1px rgba(59, 130, 246, 0.1);
position: relative;
overflow: hidden;
}

.generateButton:hover:not(:disabled) {
transform: translateY(-2px);
box-shadow: 0 6px 8px -1px rgba(59, 130, 246, 0.3), 0 4px 6px -1px rgba(59, 130, 246, 0.2);
background: linear-gradient(135deg, #2563eb 0%, #1d4ed8 100%);
}

.generateButton:active:not(:disabled) {
transform: translateY(0);
box-shadow: 0 2px 4px -1px rgba(94, 143, 223, 0.937);
}

.generateButton:disabled {
background: linear-gradient(135deg, #467bd7 0%, #6b7280 100%);
cursor: not-allowed;
opacity: 0.7;
}

.generating {
position: relative;
background: linear-gradient(135deg, #c2d1eb 0%, #779de8 100%);
cursor: not-allowed;
}

/* Optional: Add an icon before the text */
.generateButton::before {
content: '✨';
margin-right: 8px;
font-size: 18px;
}

/* Optional: Add a container for better spacing */
.generateButtonContainer {
padding: 16px 0;
text-align: center;
}

.addButton:hover, .generateButton:hover {
background: #a0b4f1;
}

.finalLetterContainer {
display: flex;
justify-content: center;
align-items: center;
min-height: 400px;
background: #fff;
border-radius: 8px;
padding: 2rem;
}

.uploadSection {
text-align: center;
}

.uploadText {
color: #666;
margin-bottom: 1.5rem;
}

.uploadButton {
display: inline-flex;
align-items: center;
gap: 0.5rem;
padding: 0.75rem 1.5rem;
background: #f0f0f0;
border: none;
border-radius: 4px;
cursor: pointer;
font-size: 1rem;
}

.uploadButton:hover {
background: #e0e0e0;
}

.uploadIcon {
font-size: 1.2rem;
}

.mainLayout {
display: grid;
grid-template-columns: repeat(3, 1fr);
gap: 24px;
margin-bottom: 32px;
}

.formSection {
flex: 1;
min-width: unset;
background-color: #fff;
border: 1px solid #dde2e5;
border-radius: 20px;
padding: 24px;
height: fit-content;
box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.formSection h3 {
font-size: 18px;
color: #004788;
margin-bottom: 20px;
font-family: sans-serif;
}

.formFields {
display: grid;
grid-template-columns: repeat(2, 1fr);
gap: 16px;
}

.formField {
flex: 1;
display: flex;
flex-direction: column;
gap: 8px;
}

.fullWidth {
grid-column: span 2;
}

/* Responsive design */
@media (max-width: 1200px) {
.mainLayout {
  grid-template-columns: 1fr;
}

.formFields {
  grid-template-columns: 1fr;
}

.fullWidth {
  grid-column: span 1;
}
}
.errorText {
color: red;
font-size: 12px;
margin-top: 5px;
font-size: 14px;
}

.buttonContainer {
display: flex;
flex-direction: row;
justify-content: flex-end;
gap: 12px;
margin-top: 2rem;
padding: 20px;
position: sticky;
bottom: 0;
right: 0;
border-top: 1px solid #e2e8f0;
}

.buttonContainer button {
min-width: 120px;
height: 40px;
border-radius: 8px;
font-size: 14px;
font-weight: 500;
}

/* Style for secondary buttons (Back to Dashboard and Save) */
.buttonContainer button[variant="secondary"] {
background-color: white;
border: 1px solid #e2e8f0;
color: #4a5568;
}

/* Style for primary button (Continue/Submit) */
.buttonContainer button[variant="primary"] {
background-color: #3b82f6;
border: none;
color: white;
}

/* Hover states */
.buttonContainer button:hover:not(:disabled) {
opacity: 0.9;
}

/* Disabled state */
.buttonContainer button:disabled {
opacity: 0.6;
cursor: not-allowed;
}

.goalsList {
display: flex;
flex-direction: column;
gap: 16px;
margin-top: 16px;
width: 100%;
}

.goalCheckbox {
display: flex;
align-items: flex-start;
gap: 12px;
padding: 16px;
border: 1px solid #e2e8f0;
border-radius: 12px;
background-color: #ffffff;
transition: all 0.2s ease;
cursor: pointer;
}

.goalCheckbox:hover {
border-color: #3b82f6;
background-color: #f8fafc;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.goalCheckbox input[type="checkbox"] {
margin-top: 4px;
width: 20px;
height: 20px;
cursor: pointer;
accent-color: #3b82f6;
}

.goalCheckbox label {
flex: 1;
cursor: pointer;
font-size: 14px;
line-height: 1.5;
color: #334155;
font-weight: 500;
}

.goalCheckbox:has(input:checked) {
border-color: #3b82f6;
background-color: #f0f7ff;
}

.endorsementSection {
margin-top: 0px;
padding: 24px;
background-color: #ffffff;
border-radius: 12px;
border: 1px solid #e2e8f0;
}

.endorsementSection h3 {
color: #004788;
font-size: 18px;
font-weight: 600;
margin-bottom: 8px;
font-family: sans-serif;
}
.generateButtonContainer h2 {
font-family: sans-serif;
color: #004788;
font-size: 24px;
font-weight: 600;
margin-bottom: 16px;
}

.helperText {
color: #1e70bb;
font-size: 0.9rem;
margin: 0.5rem 0;
}
.itemTitle {
font-weight: bold;
margin-bottom: 0.25rem;
color: #004788;
font-size: 16px;
font-family: sans-serif;
}
.itemDescription {
font-size: 0.9rem;
color: #666;
}

.pdfViewer {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 500px;
  padding: 20px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.pdfControls {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-top: 16px;
  padding: 8px;
  background-color: white;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.pageButton {
  padding: 8px 16px;
  background-color: #1e70bb;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.pageButton:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

.pageButton:hover:not(:disabled) {
  background-color: #165999;
}

.pageInfo {
  font-size: 14px;
  color: #4a5568;
}

.pdfLoading, .pdfError {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 400px;
  text-align: center;
  color: #4a5568;
}