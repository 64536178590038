.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8fafc;
}

.pageLayout {
display: flex;
padding: 20px;
}

.mainContent {
flex: 1;
background-color: #ffffff00;
border-radius: 12px;
box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.wrapper {
position: relative;
flex: 1;
padding: 35px 24px;
height: calc(100vh - 112px);
overflow-y: auto;
display: flex;
flex-direction: column;
background-color: #f8fafc;
}

.recommenderContainer {
min-width: 575px;
background-color: #fff;
border: 1px solid #dde2e5;
border-radius: 20px;
padding: 37px 43px;
margin-bottom: 32px;
position: relative;
top: 20px;
display: flex;
flex-direction: column;
align-items: center;
}

.recommenderGrid {
display: grid;
grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
gap: 24px;
width: 100%;
padding: 20px;
}

.recommenderBox {
background: #ffffff;
border: 1px solid #e0e0e0;
border-radius: 12px;
padding: 24px;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
transition: all 0.2s ease;
height: 150px;
display: flex;
align-items: center;
justify-content: center;
}
.title {
position: relative;
top: 10px;
left: 0%;
font-size: 30px;
line-height: 25px;
font-weight: 500;
font-family: Roboto;
color: #004788;
margin-bottom: 32px;
padding-left: 24px;
}

.recommenderInfo {
text-align: center;
font-size: 14px;
font-family:  sans-serif;
color: #004788;
}

.recommenderInfo h3 {
margin: 0 0 8px 0;
font-size: 16px;
color: #333;
font-weight: 500;
}

.recommenderInfo p {
margin: 4px 0;
font-size: 14px;
color: #666;
}

.addButton {
background: none;
border: 2px dashed #e0e0e0;
border-radius: 12px;
cursor: pointer;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
gap: 8px;
color: #666;
font-size: 16px;
width: 100%;
height: 100%;
}

.plusIcon {
font-size: 24px;
color: #666;
}

.addButton:hover {
border-color: #3b82f6;
color: #3b82f6;
}

.addButton:hover .plusIcon {
color: #3b82f6;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* min-height: 300px; */
  /* padding: 2rem; */
  background: #ffffff;
  /* border: 1px solid #dde2e5; */
  /* border-radius: 20px; */
  /* box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05); */
}

.loadingSpinner {
  width: 50px;
  height: 50px;
  border: 4px solid #f3f3f3;
  border-top: 4px solid #004788;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 1.5rem;
}

.loadingText {
  font-size: 18px;
  color: #004788;
  font-weight: 500;
  margin-bottom: 0.5rem;
  font-family: sans-serif;
}

.loadingSubtext {
  font-size: 14px;
  color: #64748b;
  font-family: sans-serif;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}