.customSelect {
  position: relative;
  width: 100px;
}

.selectTrigger {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background: white;
  cursor: pointer;
  height: 40px;
}

.arrow {
  margin-left: auto;
  font-size: 10px;
  color: #666;
}

.optionsList {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  margin-top: 4px;
  background: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 200px;
  overflow-y: auto;
  z-index: 1000;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
}

.option {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: 8px 12px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.option:hover {
  background-color: #f5f5f5;
}

.flagIcon {
  width: 24px;
  height: 16px;
  object-fit: cover;
}

.phoneInputGroup {
  display: flex;
  gap: 10px;
  align-items: center;
}

.phoneInput {
  flex: 1;
  height: 40px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Add custom scrollbar styles for the dropdown */
.optionsList::-webkit-scrollbar {
  width: 8px;
}

.optionsList::-webkit-scrollbar-track {
  background: #f1f1f1;
}
