/* Reset default styles */
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.menu {
  width: 240px;
  background-color: #fff;
  border-right: 1px solid #efefef;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 1024px;
}
.logo {
  width: 140px;
  height: 140px;
  object-fit: contain;
  top: -100px;
}
/* Sidebar specific styles */
.top {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 44px;
}

.bottom {
  align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  /* gap: 4px; */
  width: 100%; /* Add this */
}
.text1 {
  width: 146px;
  position: relative;
  line-height: 140%;
  display: inline-block;
  flex-shrink: 0;
  font-family: 'Open Sans', sans-serif; /* Add consistent font */
}

.text2 {
  position: relative;
  line-height: 140%;
  font-family: 'Open Sans', sans-serif; /* Add consistent font */
}

.duckUi {
  padding-top: 16px;
  position: relative;
    display: inline-block;
  width: 150px;
  font-weight: bold;
  font-family: 'Open Sans', sans-serif; /* Add consistent font */

}
.duckuidemocom {
  width: 150px;
  position: relative;
  font-size: 14px;
  color: #1f1f22;
  display: inline-block;
  font-family: 'Open Sans', sans-serif; /* Add consistent font */
}

.profile {
  display: flex;
  align-items: center;
  gap: 12px;
  width: 100%; /* Add this */
}


.listitem {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 24px;
  width: 100%; /* Add this */
}

.listManu {
  border-radius: 8px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: 16px;
  cursor: pointer;
  width: 100%; /* Add this */
}

.listManu:hover {
  background-color: #f5f5f5;
}

.content {
  width: 186px;
    height: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 16px;
}


.wrapper {
  flex: 1;
  padding: 35px 24px;
  height: 100vh;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}

.createYourProfile {
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  font-family: Roboto;
  color: #004788;
  margin-bottom: 32px;
  padding-left: 24px;
}


.formSection {
  min-width: 575px;
  background-color: #fff;
  border: 1px solid #dde2e5;
  border-radius: 20px;
  padding: 37px 43px;
  margin-bottom: 32px;
}
.buttoncontainer {
  min-width: 575px;
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  padding: 37px 43px;
  background-color: #fff;
  border: 1px solid #dde2e5;
  border-radius: 20px;
}

.formFields {
  display: flex;
  flex-direction: column;
}

.formActions {
  min-width: 575px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 40px;
  margin-top: 32px;
  background-color: #fff;
  /* border: 1px solid #dde2e5; */
  border-radius: 20px;
}

.projectInformation {
  position: relative;
  font-size: 24px;
  color: #2c3e50;
  margin-bottom: 24px;
  font-weight: 600;
}
.formRow {
  display: flex;
  gap: 44px;
  margin-bottom: 20px;
}

.textArea {
  width: 100%;
  min-height: 100px;
  padding: 14px;
  font-size: 15px;
  color: #495057;
  background-color:  rgba(239, 241, 249, 0.6);
  border: 1px solid #dee2e6;
  border-radius: 10px;
  box-sizing: border-box;
  resize: vertical;
  font-family: 'Inter', sans-serif;
  transition: all 0.2s ease-in-out;
  margin-bottom: 12px;
}

.textArea:focus {
  border-color: #1e70bb;
  background-color: #ffffff;
  outline: none;
  box-shadow: 0 0 0 3px rgba(30, 112, 187, 0.1);
}

.textArea::placeholder {
  color: #adb5bd;
  font-size: 14px;
}

.inputField {
  flex: 1;
  padding: 12px;
  border: 1px solid #dde2e5;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  transition: border-color 0.2s ease-in-out;
}

.inputField::placeholder {
  color: #999;
}

.inputField:focus {
  outline: none;
  border-color: #1e70bb;
}

/* .inputField.errorInput:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.2rem rgba(220, 53, 69, 0.25);
} */

.uploadContainer {
  margin-top: 24px;
}

.fileInput {
  width: 100%;
  padding: 8px;
  border: 1px dashed #e0e0e0;
  border-radius: 4px;
}

.primaryButton, .secondaryButton {
  width: 200px;
  border-radius: 12px;
  padding: 14px 24px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  font-weight: 500;
}

.primaryButton {
  background-color: #1e70bb;
  color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(30, 112, 187, 0.2);
}

.secondaryButton {
  background-color: #fff;
  color: #1e70bb;
  border: 2px solid #1e70bb;
}

/* .errorText {
  color: #d32f2f;
  font-size: 12px;
  margin-top: 4px;
} */





.createYourProfile {
  position: relative;
  top: 10px;
  left: 0%;
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  font-family: Roboto;
  color: #004788;
  display: inline-block;
}

.navButtons {
  display: flex;
  gap: 16px;
}

.primaryButton:hover {
  background-color: #1a62a3;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(30, 112, 187, 0.25);
}

.secondaryButton:hover {
  background-color: rgba(30, 112, 187, 0.05);
  transform: translateY(-1px);
}

.stepper {
	align-self: stretch;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
  gap: 16px;
  text-align: right;
  font-size: 14px;
  color: #1e70bb;
  margin-bottom: 32px; 
  }

  /* stepper */
  .step1 {
	font-weight: 500;
  }
  .of4 {
	color: #83898c;
  }
  .barcontainer {
	align-self: stretch;
	position: relative;
	height: 12px;
	width: 100%;
  }
  .bg {
	position: absolute;
	top: 3px;
	left: 0px;
	right: 0px;
	border-radius: 20px;
	background-color: #e2e6f90f;
	/* width: 100%; */
	height: 6px;
  }
  .bar {
	position: absolute;
	top: 0px;
	left: 0px;
	border-radius: 20px;
	background-color: #1e70bb;
	width: 100%;
	height: 12px;
	transition: width 0.3s ease;
  }

  /* file upload */
  .fileUploadContainer {
    margin-top: 8px;
    }
  
  .fileInput {
    display: none;
    }
    
    .fileInputLabel {
    display: block;
    width: 35%;
    height: 40px;
    padding: 8px 16px;
    border: 1px solid #E6E6E6;
    border-radius: 4px;
    font-size: 14px;
    line-height: 20px;
    background-color: #fff;
    cursor: pointer;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-family: 'Open Sans', sans-serif;
    color: #1e70bb;
    }

.uploadedFiles {
  margin-top: 10px;
}

.fileItem {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: #f5f5f5;
  padding: 8px 12px;
  margin: 5px 0;
  border-radius: 4px;
}

.removeFile {
  background: none;
  border: none;
  color: #ff4444;
  cursor: pointer;
  font-size: 18px;
  padding: 0 5px;
}

.removeFile:hover {
  color: #cc0000;
}

/* .fileInputLabel {
  display: inline-block;
  padding: 12px 20px;
  background-color: #f5f5f5;
  border: 1px dashed #ccc;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
} */

.fileInputLabel:hover {
  background-color: #eee;
  border-color: #1e70bb;
}
.inputLabel {
  font-size: 14px;
  color: #9a9898;
  margin-bottom: 8px;
}


.buttonGroup {
  display: flex;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 24px;
}

.saveButton {
  padding: 12px 24px;
  background: #ffffff;
  border: 1px solid #1e70bb;
  color: #1e70bb;
  border-radius: 8px;
  cursor: pointer;
  font-weight: 500;
  transition: all 0.2s ease;
}

.saveButton:hover {
  background: rgba(30, 112, 187, 0.1);
}

.errorMessage {
  color: #dc3545;
  /* background-color: #f8d7da; */
  /* border: 1px solid #f5c6cb; */
  /* border-radius: 4px; */
  /* padding: 12px; */
  margin-bottom: 0px;
  text-align: center;
	font-size: 12px;
	margin-top: 0px;
}

.textAreaRow {
  display: flex;
  gap: 12px;
  margin-bottom: 12px;
}

.textAreaRow .textArea {
  flex: 1;
}

/* Add smooth scrolling to the page */
html {
  scroll-behavior: smooth;
}

/* Add custom scrollbar */
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb:hover {
  background: #a8a8a8;
}

.projectHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  cursor: pointer;
}

.headerActions {
  display: flex;
  align-items: center;
  gap: 16px;
}

.deleteIcon {
  width: 30px;
  height: 30px;
  cursor: pointer;
  color: #090909;
  transition: color 0.2s ease;
  padding: 2px;
}

.deleteIcon:hover {
  color: #ff1f1f;
}

.dropdownIcon {
  width: 24px;
  height: 24px;
  transition: transform 0.3s ease;
}

.dropdownIcon.open {
  transform: rotate(180deg);
}

.projectContent {
  display: none;
  padding-top: 16px;
}

.projectContent.open {
  display: block;
}

/* .errorInput {
  border-color: #dc3545;
} */


.inputWrapper {
  flex: 1;
  position: relative;
}


.inputField {
  width: 100%;
  padding: 12px;
  border: 1px solid #dde2e5;
  border-radius: 8px;
  font-size: 14px;
  color: #333;
  transition: all 0.2s ease-in-out;
}
/* 
.inputField.errorInput {
  border-color: #ff4d4f;
} */

/* .textArea.errorInput {
  border-color: #ff4d4f;
} */

.delete-project-button {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.btn-danger {
  background-color: #dc3545;
  color: white;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 4px;
  cursor: pointer;
}

.btn-danger:hover {
  background-color: #c82333;
}

.fileUploadSection {
  display: flex;
  flex-direction: column;
  gap: 24px;  /* Match the formRow gap */
  margin-top: 24px;  /* Match other spacing */
}

.fileUploadRow {
  display: flex;
  gap: 24px;  /* Match the formRow gap */
  width: 100%;
  margin-bottom: 24px;  /* Match formRow margin-bottom */
}

.fileUploadItem {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.inputLabel {
  font-size: 14px;
  color: #9a9898;  /* Match other input labels */
  margin-bottom: 8px;
  margin-top: 12px;
}

.pageLayout {
  display: flex;
  min-height: 100vh;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}



.wrapper {
  flex: 1;
  padding: 35px 24px;
  height: 100vh;
  overflow-x: auto;
  display: flex;
  flex-direction: column;
}
.createProfile {
  display: flex;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  /* background-color: #fff; */
  font-family: 'Open Sans', sans-serif;
  flex: 1;
  background-color: #ffffff00;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}
.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8fafc;
}
.pageLayout {
  display: flex;
  flex: 1;
  /* margin-top: 64px; */
  padding: 20px;
  gap: 20px;
}
.formcontainer {
max-width: 1000px;
position: relative;
margin: 0 auto;
padding: 0px;
}
.projectTitle {
  font-size: 24px;
  font-weight: 600;
  color: #2c3e50;
  font-family: 'Inter', sans-serif;
}