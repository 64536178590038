.dropdownWrapper {
    position: relative;
    width: 100%;
  }
  
  .label {
    display: block;
    font-size: 14px;
    color: #4A5568;
    margin-bottom: 8px;
    font-weight: 500;
    font-family: 'Inter', sans-serif;
  }
  
  .required {
    color: #E53E3E;
    margin-left: 2px;
  }
  
  .dropdownTrigger {
    width: 100%;
    padding: 12px 16px;
    background-color: rgba(239, 241, 249, 0.6);
    border: 1px solid #dde2e5;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    transition: all 0.2s ease-in-out;
    min-height: 45px;
  }
  
  .dropdownTrigger:hover:not(.disabled) {
    border-color: #1e70bb;
    background-color: rgba(239, 241, 249, 0.8);
  }
  
  .dropdownTrigger.error {
    border-color: #E53E3E;
    background-color: #FFF5F5;
  }
  
  .dropdownTrigger.disabled {
    background-color: #F7FAFC;
    cursor: not-allowed;
    opacity: 0.7;
  }
  
  .selectedValue {
    font-size: 14px;
    color: #2D3748;
    font-family: 'Inter', sans-serif;
  }
  
  .placeholder {
    color: #A0AEC0;
    font-family: 'Inter', sans-serif;
  }
  
  .arrow {
    width: 20px;
    height: 20px;
    color: #718096;
    transition: transform 0.2s ease;
  }
  
  .arrow.open {
    transform: rotate(180deg);
  }
  
  .optionsList {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    background: white;
    border: 1px solid #E2E8F0;
    border-radius: 8px;
    max-height: 250px;
    overflow-y: auto;
    z-index: 1000;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
    font-family: 'Inter', sans-serif;
  }
  
  .option {
    padding: 12px 16px;
    font-size: 14px;
    color: #4A5568;
    cursor: pointer;
    transition: all 0.2s ease;
  }
  
  .option:hover {
    background-color: #EBF8FF;
  }
  
  .option.selected {
    background-color: #E6F6FF;
    color: #1e70bb;
    font-weight: 500;
  }
  
  .errorMessage {
    color: #E53E3E;
    font-size: 12px;
    margin-top: 4px;
    display: block;
  }
  
  /* Scrollbar styling */
  .optionsList::-webkit-scrollbar {
    width: 8px;
  }
  
  .optionsList::-webkit-scrollbar-track {
    background: #F7FAFC;
    border-radius: 8px;
  }
  
  .optionsList::-webkit-scrollbar-thumb {
    background: #CBD5E0;
    border-radius: 8px;
  }
  
  .optionsList::-webkit-scrollbar-thumb:hover {
    background: #A0AEC0;
  }