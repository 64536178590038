:root {
    --container-max-width: min(1440px, 90vw);
    --section-padding: clamp(1rem, 5vw, 8rem);
    --content-width: clamp(280px, 90vw, 1200px);
    --hero-gap: clamp(1rem, 3vw, 4rem);
    --primary-color: #0B82EA;
    --secondary-color: #000000;
    --background-light: #F6F6F6;
    --text-primary: #000000;
    --text-secondary: #525252;
    --font-family: 'DM Sans', -apple-system, BlinkMacSystemFont, sans-serif;
}

.landing-page {
    width: min(100%, var(--container-max-width));
    margin: 0 auto;
    overflow-x: hidden;
    font-family: var(--font-family);
}

/* Hero Section */
.hero {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--section-padding);
    gap: var(--hero-gap);
    background: linear-gradient(120deg, 
        rgba(255,255,255,1) 0%,
        rgba(236,246,255,0.8) 50%,
        rgba(236,255,244,0.8) 100%
    );
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    width: 100%;
}

/* Container wrapper to ensure proper centering */
.hero > div {
    width: 100%;
    max-width: var(--content-width);
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: var(--hero-gap);
}

/* Hero left content container */
.heroLeft {
    flex: 1;
    max-width: min(600px, 45%);
    min-width: min(280px, 100%);
    text-align: left;
    padding-right: 20px;
    position: relative;
    z-index: 2;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Hero title and text alignment */
.heroTitle {
    font-family: 'Inter', sans-serif;
    font-size: clamp(24px, 3.5vw, 40px);
    font-weight: 800;
    line-height: 1.2;
    color: #111827;
    margin-bottom: 1em;
    letter-spacing: -0.02em;
    white-space: normal;
    width: 100%;
    text-align: left;
    margin-left: 0;
}

/* Mobile-specific adjustments */
@media screen and (max-width: 768px) {
    .heroTitle {
        font-size: clamp(20px, 6vw, 32px);
        white-space: normal;
        line-height: 1.3;
        margin-bottom: 0.8em;
    }
}

/* Small mobile devices */
@media screen and (max-width: 480px) {
    .heroTitle {
        font-size: clamp(18px, 5vw, 28px);
        line-height: 1.4;
        margin-bottom: 0.6em;
    }
}

/* Extra small devices */
@media screen and (max-width: 320px) {
    .heroTitle {
        font-size: clamp(16px, 4.5vw, 24px);
        line-height: 1.3;
        margin-bottom: 0.5em;
    }
}

/* Android-specific media query */
@media screen and (max-width: 768px) and (-webkit-min-device-pixel-ratio: 1.5) {
    .heroTitle {
        font-size: clamp(18px, 5vw, 30px);
        line-height: 1.3;
        letter-spacing: -0.01em;
    }
}

.highlight {
    display: block;
    width: fit-content;
    margin: 0.5em 0;
    padding: 0.5em 1em;
    background: linear-gradient(120deg, #dbeafe 0%, #bfdbfe 100%);
    border-radius: 6px;
    color: #1e3a8a;
    font-weight: 700;
    transition: all 0.3s ease;
}

/* Optional hover effect */
.highlight:hover {
    background: linear-gradient(120deg, 
        rgba(37, 99, 235, 0.15) 0%,
        rgba(59, 130, 246, 0.2) 50%,
        rgba(37, 99, 235, 0.15) 100%
    );
}

.heroText {
    font-family: 'Inter', sans-serif;
    font-size: clamp(1rem, 2vw, 1.25rem);
    font-weight: 400;
    line-height: 1.6;
    color: #374151;
    margin-bottom: clamp(1rem, 3vw, 2rem);
    letter-spacing: -0.02em;
    width: 100%;
    max-width: 500px;
    text-align: left;
    margin-left: 0;
    padding-left: 0;
    align-self: flex-start;
}

/* Add responsive handling for smaller screens */
/* @media (max-width: 768px) {
    .heroLeft {
        max-width: 100%;
        padding: 0 20px;
    }

    .heroTitle,
    .heroText {
        width: 100%;
        min-width: unset;
    }
} */

.getStarted {
    background: #2563eb;
    color: #ffffff;
    font-size: 1vw;
    font-weight: 600;
    border: none;
    border-radius: 8px;
    padding: 0.75em 1.5em;
    cursor: pointer;
    transition: all 0.2s ease;
    box-shadow: 0 4px 6px rgba(37, 99, 235, 0.1);
    min-width: 100px;
    max-width: 200px;
        align-self: flex-start; /* Align button to the left */

}

.getStarted:hover {
    background: #1d4ed8;
    transform: translateY(-2px);
    box-shadow: 0 6px 8px rgba(37, 99, 235, 0.15);
}

/* TrustedBy section alignment */
.trustedBy {
    width: 100%;
    max-width: var(--content-width);
    margin-top: clamp(2rem, 5vw, 5rem);
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.trustedText {
    width: 480px;
    padding: 0;
    margin-left: 0;
    margin-right: 0;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.4);
    margin-bottom: 16px;
    text-align: left;
}

.logoGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    width: 100%;
    min-width: max-content;
}

.logoGroup img {
    height: 20px;
    opacity: 0.6;
    transition: opacity 0.3s ease;
}

.logoGroup img[alt="Meta logo"] {
    height: 80px;
    width: 80px;
}

.logoGroup img[alt="McKinsey logo"] {
    height: 80px;
    width: 80px;
}

.logoGroup img[alt="TikTok logo"] {
    height: 30px;
    width: 90px;
}

.divider {
    width: 1px;
    height: 28px;
    background: rgba(0, 0, 0, 0.1);
}

.heroRight {
    flex: 1;
    max-width: min(600px, 50%);
    min-width: min(280px, 100%);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    overflow: visible;
    perspective: 1000px;
}

.dashboardImage {
    width: 100%;
    height: auto;
    max-width: 100%;
    border-radius: 16px;
    object-fit: contain;
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
    box-shadow: 
        0 5px 15px rgba(0, 0, 0, 0.1),
        /* 0 15px 35px rgba(0, 0, 0, 0.1), */
        /* 0 50px 100px rgba(0, 0, 0, 0.1), */
        inset 0 1px 0 rgba(255, 255, 255, 0.1);
}

@keyframes slideInWithRotate {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .hero {
        flex-direction: column;
        padding: 60px 20px;
    }

    .heroRight {
        max-width: 80%;
        margin: 0 auto;
        margin-right: 20px
    }

    .dashboardImage {
        width: 100%;
    }
}

@media (max-width: 768px) {
    .hero {
        padding: 40px 20px;
    }

    .heroRight {
        max-width: 90%;
    }
}

@media (max-width: 480px) {
    .hero {
        padding: 30px 15px;
    }

    .heroRight {
        max-width: 90%;
    }
}
/* Add floating animation */
.heroRight.animate .dashboardImage {
    animation:  6s ease-in-out infinite;
}

/* @keyframes float {
    0% {
        transform: rotateY(-3deg) rotateX(2deg) translateY(0px);
    }
    50% {
        transform: rotateY(-3deg) rotateX(2deg) translateY(-20px);
    }
    100% {
        transform: rotateY(-3deg) rotateX(2deg) translateY(0px);
    }
} */

/* Add hover effect */
.heroRight:hover .dashboardImage {
    box-shadow: 
        0 10px 25px rgba(0, 0, 0, 0.15),
        /* 0 25px 50px rgba(0, 0, 0, 0.12), */
        /* 0 60px 120px rgba(0, 0, 0, 0.12), */
        inset 0 1px 0 rgba(255, 255, 255, 0.2);
}

/* Add reflection effect */
.dashboardImage::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    height: 50%;
    background: linear-gradient(
        to bottom,
        rgba(255, 255, 255, 0.1) 0%,
        transparent 100%
    );
    border-radius: 16px 16px 0 0;
    pointer-events: none;
}

/* Background glow effect */
.heroRight::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 80%;
    height: 80%;
    background: radial-gradient(
        circle,
        rgba(255, 255, 255, 0.1) 0%,
        transparent 70%
    );
    filter: blur(40px);
    z-index: -1;
}

/* Responsive adjustments */
@media (max-width: 1440px) {
    .dashboardImage {
        width: 100%;
        margin-left: 0;
    }
    
    .heroRight {
        max-width: 100%;
    }
}

@media (max-width: 1024px) {
    .heroRight {
        max-width: 100%;
    }
    
    .dashboardImage {
        width: 100%;
        margin-left: 0;
    }
}

/* Add sliding animation from left */
@keyframes slideInFromLeft {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

/* Add sliding animation */
@keyframes slideInFromRight {
    0% {
        /* transform: translateX(100px); */
        opacity: 0;
    }
    100% {
        /* transform: translateX(0); */
        opacity: 1;
    }
}

/* Add animated gradient background */
.hero::before {
    display: none;
}

/* Stats Section */
.stats {
    position: relative;
    margin-top: -100px;
    margin-bottom: -100px;
    padding: 0 120px;
    z-index: 10;
}

.statsContainer {
    background: #FFFFFF;
    border-radius: 24px;
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.08),
        0 10px 20px rgba(0, 0, 0, 0.04);
    max-width: 1200px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    padding: 48px;
    gap: 32px;
    position: relative;
    z-index: 10;
}

/* Ensure hero sections stay behind */
.hero, .process {
    position: relative;
    z-index: 1;
}

.statsItem {
    text-align: center;
    position: relative;
}

.statsItem:not(:last-child)::after {
    content: '';
    position: absolute;
    right: -16px;
    top: 50%;
    transform: translateY(-50%);
    height: 60%;
    width: 1px;
    background: rgba(0, 0, 0, 0.1);
}

.statsNumber {
    font-family: 'Roboto Mono', monospace;
    font-size: 64px;
    font-weight: 700;
    line-height: 1;
    display: block;
    margin-bottom: 16px;
    color: #3B82F6;
}

.statsText {
    font-family: 'Roboto Mono', monospace;
    font-size: 14px;
    color: #374151;
    line-height: 1.4;
    text-transform: uppercase;
}

/* Animation for the counter */
@keyframes countUp {
    from {
        transform: translateY(10px);
        opacity: 0;
    }
    to {
        transform: translateY(0);
        opacity: 1;
    }
}

.statsNumber.animate {
    animation: countUp 0.5s ease-out forwards;
}

/* Responsive adjustments */
@media (max-width: 1024px) {
    .stats {
        margin-top: -80px;
        margin-bottom: -80px;
        padding: 0 40px;
    }
    
    .statsContainer {
        padding: 32px;
    }
    
    .statsNumber {
        font-size: 48px;
    }
}

@media (max-width: 768px) {
    .stats {
        margin-top: -60px;
        margin-bottom: -60px;
        padding: 0 20px;
    }
    
    .statsContainer {
        grid-template-columns: repeat(2, 1fr);
        gap: 24px;
    }
    
    .statsItem:nth-child(2)::after {
        display: none;
    }
}

/* Process Section */
.process {
    background: linear-gradient(135deg,
        #66a9ec 0%,
        #3B82F6 45%,
        #60A5FA 100%
    );
    position: relative;
    padding: 140px 120px;
    overflow: hidden;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 140px 120px;
    min-height: 90vh;
    gap: 100px;
}



.processLeft {
    flex: 0 1 480px;
    padding-top: 40px;
    position: relative;
    z-index: 2;
}

.processRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    perspective: 1000px;
}

.processTitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    color: #FFFFFF;
    margin-bottom: 8px;
    opacity: 0;
    transform: translateY(20px);
}

.processSubtitle {
    font-family: 'Inter', sans-serif;
    font-size: 42px;
    font-weight: 700;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 24px;
    opacity: 0;
    transform: translateY(20px);
}

.processText {
    font-size: 18px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 32px;
    opacity: 0;
    transform: translateY(20px);
}

.createProfile {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    background: #FFFFFF;
    color: #2563EB;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 28px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
}

.buttonIcon {
    transition: transform 0.3s ease;
}

.createProfile:hover .buttonIcon {
    transform: translateX(4px);
}

.createProfile:hover {
    background: rgba(255, 255, 255, 0.95);
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

/* Mockup styles */
.mockupContainer {
    width: 100%;
    max-width: 600px;
    height: auto;
    position: relative;
}

.mockupWindow {
    background: #FFFFFF;
    border-radius: 12px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.windowHeader {
    background: #F8FAFC;
    padding: 12px 16px;
    border-bottom: 1px solid #E2E8F0;
    display: flex;
    align-items: center;
}

.windowDots {
    display: flex;
    gap: 6px;
    margin-right: 12px;
}

.windowDots span {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: #E2E8F0;
}

.windowTitle {
    color: #64748B;
    font-size: 14px;
}

.windowContent {
    padding: 24px;
}

.formField {
    margin-bottom: 16px;
}

.formField label {
    display: block;
    color: #64748B;
    font-size: 14px;
    margin-bottom: 8px;
}

.inputPlaceholder {
    height: 40px;
    background: #F1F5F9;
    border-radius: 6px;
}

.securityBadge {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background: #F8FAFC;
    border-radius: 20px;
    font-size: 14px;
    color: #64748B;
}

@media (max-width: 1024px) {
    .process > div {
        padding: 80px 40px;
    }
    
    .processLeft,
    .processRight {
        max-width: 100%;
        padding: 0 20px;
        margin: 0;
    }
    
    .processTitle {
        font-size: 36px;
    }
    
    .processSubtitle {
        font-size: 32px;
    }
}

/* AI Letter Section */
.ai-letter {
    padding: 120px 125px;
    background: #F6F3F0;
}

.ai-letter h2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 1.2;
    max-width: 582px;
    margin-bottom: 30px;
}

.ai-letter p {
    font-size: 16px;
    line-height: 1.5;
    color: var(--text-secondary);
    max-width: 449px;
    margin-bottom: 40px;
}

/* Pricing Section */
.pricing {
    padding: 120px 125px;
    background: #FFFFFF;
}

.pricing h2 {
    font-size: 64px;
    font-weight: 700;
    line-height: 1.2;
    margin-bottom: 60px;
}

.pricing__grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 30px;
}

/* Buttons */
.btn-primary {
    background: rgba(11, 130, 234, 0.2);
    color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 32px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.btn-secondary {
    background: rgba(0, 0, 0, 0.2);
    color: #FFFFFF;
    padding: 10px 20px;
    border-radius: 6px;
    font-size: 24px;
    font-weight: 700;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

/* Responsive Design */
@media (max-width: 1200px) {
    .hero__content {
        max-width: 100%;
    }

    .pricing__grid {
        grid-template-columns: repeat(2, 1fr);
    }

    .process__illustration,
    .hero__background {
        display: none;
    }
}

@media (max-width: 1024px) {
    .statsContainer {
        grid-template-columns: repeat(2, 1fr);
        gap: 40px;
    }

    .statsItem:nth-child(2)::after {
        display: none;
    }

    .process > div {
        flex-direction: column;
        text-align: center;
    }

    .processTitle {
        font-size: 48px;
    }
}

@media (max-width: 768px) {
    .hero,
    .process,
    .ai-letter,
    .pricing {
        padding: 60px 20px;
    }

    .stats {
        grid-template-columns: repeat(2, 1fr);
        margin: -70px 20px 0;
        padding: 20px;
    }

    .stats__item {
        border-right: none;
        border-bottom: 1px solid rgba(0, 0, 0, 0.1);
        padding: 20px 0;
    }

    .stats__item:nth-last-child(-n+2) {
        border-bottom: none;
    }

    .pricing__grid {
        grid-template-columns: 1fr;
    }

    .hero__content h1 {
        font-size: 40px;
    }

    .hero__content p {
        font-size: 18px;
    }

    .process h2,
    .ai-letter h2,
    .pricing h2 {
        font-size: 36px;
    }

    .logoGroup img[alt="Meta logo"],
    .logoGroup img[alt="McKinsey logo"] {
        height: 24px;
    }
    
    .logoGroup img[alt="TikTok logo"] {
        height: 20px;
    }
    
    .divider {
        height: 24px;
    }

    .heroRight {
        animation: none;
        opacity: 1;
    }

    .heroTitle,
    .heroText,
    .getStarted,
    .heroRight {
        animation: none;
        opacity: 1;
    }

    .statsNumber {
        font-size: 48px;
    }

    .statsText {
        font-size: 14px;
    }

    .processTitle {
        font-size: 36px;
    }

    .processText {
        font-size: 16px;
    }

    .mockupWindows {
        height: 300px;
    }

    .mockupWindow {
        height: 240px;
    }

    .secondaryWindow {
        height: 200px;
    }
}

.createProfile {
    background: white;
    color: #2A89E9;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 32px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
}

.createProfile:hover {
    background: rgba(255, 255, 255, 0.9);
    transform: translateY(-2px);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Update header styles */
:global(header) {
    position: relative;
    z-index: 10;
    background: linear-gradient(120deg, 
        rgba(255,255,255,1) 0%,
        rgba(236,246,255,0.8) 50%,
        rgba(236,255,244,0.8) 100%
    );
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

/* Adjust hero section */
/* .hero {
    position: relative;
    background: linear-gradient(120deg, 
        rgba(255,255,255,1) 0%,
        rgba(236,246,255,0.8) 50%,
        rgba(236,255,244,0.8) 100%
    );
    margin-top: 0;
    z-index: 1;
} */

/* Third Hero Section */
.expertiseHero {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 140px 120px;
    min-height: 90vh;
    gap: 100px;
    /* background: linear-gradient(120deg, 
        rgba(255,255,255,1) 0%,
        rgba(236,246,255,0.8) 50%,
        rgba(236,255,244,0.8) 100% */
    /* ); */
    background: #F8FAFC;
    overflow: hidden;
}

.expertiseLeft {
    flex: 0 1 480px;
    padding-top: 40px;
    position: relative;
    z-index: 2;
}

.expertiseContent {
    max-width: 440px;
}

/* Split title styling to match hero 4 */
.expertiseTitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    color: #111827;
    margin-bottom: 8px;
    letter-spacing: -0.02em;
}

.expertiseSubtitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    color: #4B96F8;
    margin-bottom: 24px;
    letter-spacing: -0.02em;
}

.expertiseRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    perspective: 1000px;
}

/* Enhanced 3D mockup styling */
.expertiseMockup {
    width: 100%;
    max-width: 600px;
    height: auto;
    position: relative;
}

.mockupMain {
    background: white;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 
        0 10px 30px rgba(0, 0, 0, 0.1),
        0 30px 60px rgba(0, 0, 0, 0.1);
    transform: rotateY(-5deg) rotateX(2deg);
    position: relative;
    z-index: 2;
}

.mockupSecondary {
    position: absolute;
    top: 40px;
    right: -40px;
    width: 90%;
    height: 100%;
    background: white;
    border-radius: 16px;
    padding: 24px;
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.1);
    transform: rotateY(-5deg) translateZ(-50px);
    z-index: 1;
    opacity: 0.8;
}

/* Content styling */
.mockupContent {
    background: #F8FAFC;
    border-radius: 12px;
    padding: 20px;
}

.mockupHeader {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
}

.mockupIcon {
    width: 40px;
    height: 40px;
    background: #2A89E9;
    border-radius: 8px;
    margin-right: 16px;
}

.mockupTitle {
    font-size: 20px;
    font-weight: 600;
    color: #1E293B;
}

.mockupGrid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 16px;
}

.mockupItem {
    background: white;
    border-radius: 8px;
    padding: 16px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

@media (max-width: 1024px) {
    .expertiseHero {
        min-height: auto;
        padding: 80px 40px;
        flex-direction: column;
        text-align: center;
    }

    .expertiseLeft {
        padding-top: 0;
    }

    .expertiseRight {
        width: 100%;
        justify-content: center;
    }

    .expertiseMockup {
        transform: none;
        animation: none;
    }
}

/* Add these styles for the mockup buttons */
.mockupButton {
    padding: 8px 16px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
    border: 1px solid #E2E8F0;
    background: white;
    color: #64748B;
    cursor: pointer;
    transition: all 0.2s ease;
}

.mockupButton.primary {
    background: #2A89E9;
    color: white;
    border: none;
}

.mockupFooter {
    display: flex;
    justify-content: space-between;
    margin-top: 24px;
    padding-top: 16px;
    border-top: 1px solid #E2E8F0;
}

/* Add styles for form elements */
.formLine {
    height: 12px;
    background: #E2E8F0;
    border-radius: 6px;
    margin-bottom: 8px;
}

/* Enhance mockup content */
.mockupContent h3 {
    font-size: 18px;
    font-weight: 600;
    color: #1E293B;
    margin-bottom: 20px;
}

/* Add hover effects */
.expertiseMockup:hover {
    transform: translateY(-5px);
}

.mockupButton:hover {
    background: #F8FAFC;
}

.mockupButton.primary:hover {
    background: #1B76D4;
}

/* Fourth Hero - AI Letter Generation */
.aiLetterHero {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 140px 120px;
    background: linear-gradient(120deg, 
        #FFFFFF 0%,
        #F0F9FF 35%,
        #F0FDFA 65%,
        #FAFAFA 100%
    );
    gap: 100px;
    overflow: hidden;
}

.aiLetterLeft {
    flex: 0 1 480px;
    position: relative;
    z-index: 2;
}

.aiLetterContent {
    max-width: 440px;
}

.aiLetterTitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    color: #0F172A;
    margin-bottom: 8px;
    letter-spacing: -0.02em;
}

.aiLetterSubtitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    background: linear-gradient(135deg, #0EA5E9 0%, #22D3EE 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-bottom: 24px;
    letter-spacing: -0.02em;
}

.aiLetterText {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: #374151;
    margin-bottom: 32px;
    max-width: 520px;
}

.generateButton {
    background: #0EA5E9;
    color: #FFFFFF;
    font-size: 18px;
    font-weight: 600;
    padding: 16px 32px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    gap: 8px;
}

.buttonIcon {
    transition: transform 0.2s ease;
}

.generateButton:hover {
    background: #0284C7;
    transform: translateY(-2px);
}

.generateButton:hover .buttonIcon {
    transform: translateX(4px);
}

/* Letter mockup styles */
.letterMockup {
    background: white;
    border-radius: 16px;
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.02),
        0 12px 16px rgba(0, 0, 0, 0.05),
        0 20px 40px rgba(0, 0, 0, 0.08);
    padding: 40px;
    max-width: 600px;
    margin: 0 auto;
    opacity: 0;
    transform: translateY(20px);
    animation: fadeInUp 0.8s ease-out forwards;
}

.letterMain {
    background: white;
    border: 1px solid #E5E7EB;
    border-radius: 8px;
    padding: 32px;
}

.letterHeader {
    margin-bottom: 24px;
}

.letterDate {
    color: #6B7280;
    font-size: 14px;
    margin-bottom: 16px;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards 0.3s;
}

.letterRef {
    font-weight: 600;
    color: #111827;
    font-size: 16px;
    margin-bottom: 24px;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards 0.7s;
}

.letterContent p {
    color: #374151;
    font-size: 15px;
    line-height: 1.6;
    margin-bottom: 16px;
    text-align: justify;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards 0.9s;
}

.letterHighlight {
    opacity: 0;
    animation: fadeInScale 0.6s ease-out forwards 1.1s;
}

.letterSignature {
    margin-top: 32px;
    padding-top: 24px;
    border-top: 1px solid #E5E7EB;
    opacity: 0;
    animation: fadeIn 0.5s ease-out forwards 1.3s;
}

.signatureName {
    font-weight: 600;
    font-size: 16px;
    color: #111827;
    margin-bottom: 4px;
}

.signatureTitle, .signatureInst {
    font-size: 14px;
    color: #6B7280;
    line-height: 1.4;
}

/* Add hover effect */
.letterMain:hover {
    transform: rotateY(-3deg) rotateX(1deg) translateY(-5px);
    box-shadow: 
        0 20px 40px rgba(0, 0, 0, 0.1),
        0 40px 80px rgba(0, 0, 0, 0.1);
}

/* Common text styles for both sections */
.expertiseText, .aiLetterText {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: #374151;  /* Slightly darker than previous for better readability */
    margin-bottom: 32px;
    max-width: 520px;
}

/* Update the title colors to match the image */
.expertiseTitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    color: #111827;  /* Darker, matching the image */
    margin-bottom: 8px;
    letter-spacing: -0.02em;
}

.expertiseSubtitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    color: #4B96F8;  /* Matching the blue in the image */
    margin-bottom: 24px;
    letter-spacing: -0.02em;
}

.pricingSection {
    padding: 100px 120px;
    background: #FFFFFF;
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.pricingSection.animate {
    opacity: 1;
    transform: translateY(0);
}

.pricingHeader {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.6s cubic-bezier(0.4, 0, 0.2, 1);
    text-align: center;
    margin-bottom: 48px;
}

.pricingSection.animate .pricingHeader {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.2s;
}

.pricingTitle {
    font-size: 48px;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 16px;
    letter-spacing: -0.02em;
    font-family: 'Inter', sans-serif;
}

.titleGray {
    color: #8e8f91;
}

.pricingDescription {
    font-size: 18px;
    line-height: 1.6;
    color: #4B5563;
    max-width: 600px;
    margin: 0 auto;
    font-family: 'Inter', sans-serif;
}

.textGray {
    color: #737578;
}

.pricingGrid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 24px;
    max-width: 1200px;
    margin: 12px auto 0;
    padding: 0 20px;
}

/* Add staggered animation for pricing cards */
.pricingCard {
    opacity: 0;
    transform: translateY(30px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.pricingSection.animate .pricingCard:nth-child(1) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.3s;
}

.pricingSection.animate .pricingCard:nth-child(2) {
    opacity: 1;
    transform: translateY(0);
    transition-delay: 0.4s;
}

.pricingCard {
    background: #FFFFFF;
    border: 1px solid #E5E7EB;
    border-radius: 16px;
    padding: 32px;
    transition: all 0.3s ease;
}

.pricingCardFeatured {
    background: #EFF6FF;
    border-color: #60A5FA;
}

.cardHeader {
    margin-bottom: 24px;
}

.title {
    font-size: 24px;
    font-weight: 600;
    color: #111827;
    margin: 0;
}

.features {
    list-style: none;
    padding: 0;
    margin: 0 0 32px 0;
}

.featureItem {
    display: flex;
    gap: 12px;
    margin-bottom: 16px;
    color: #4B5563;
    font-size: 15px;
    line-height: 1.5;
}

.bulletPoint {
    color: #60A5FA;
}

.priceSection {
    margin-bottom: 24px;
}

.originalPrice {
    color: #6B7280;
    text-decoration: line-through;
    font-size: 16px;
    display: block;
    margin-bottom: 4px;
}

.currentPrice {
    display: flex;
    align-items: baseline;
    gap: 2px;
}

.dollarSign {
    font-size: 20px;
    font-weight: 600;
    color: #111827;
}

.amount {
    font-size: 36px;
    font-weight: 700;
    color: #111827;
}

.period {
    font-size: 16px;
    color: #6B7280;
}

.getStarted {
    width: 100%;
    background: #2563EB;
    color: #FFFFFF;
    border: none;
    border-radius: 8px;
    padding: 12px 24px;
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.2s ease;
}

.getStarted:hover {
    background: #1D4ED8;
    transform: translateY(-2px);
}

@media (max-width: 1200px) {
    .pricingGrid {
        grid-template-columns: repeat(2, 1fr);
        gap: 32px;
    }
}

@media (max-width: 768px) {
    .pricingSection {
        padding: 80px 20px;
    }

    .pricingTitle {
        font-size: 48px;
    }

    .pricingGrid {
        grid-template-columns: 1fr;
        gap: 24px;
    }
}

.processLeft {
    max-width: 520px;
}

.processTitle {
    font-family: 'Inter', sans-serif;
    font-size: 48px;
    font-weight: 800;
    line-height: 1.1;
    color: #fdfdff;
    margin-bottom: 8px;
    letter-spacing: -0.02em;
}

.processSubtitle {
    font-family: 'Inter', sans-serif;
    font-size: 36px;
    font-weight: 700;
    line-height: 1.2;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 24px;
    opacity: 0;
    transform: translateY(20px);
}

.processText {
    font-family: 'Inter', sans-serif;
    font-size: 18px;
    line-height: 1.6;
    color: white;  /* Slightly darker than previous for better readability */
    margin-bottom: 32px;
    max-width: 520px;
}

.createProfile {
    display: inline-flex;
    align-items: center;
    gap: 12px;
    background: #FFFFFF;
    color: #2563EB;
    font-size: 16px;
    font-weight: 600;
    padding: 14px 28px;
    border-radius: 8px;
    border: none;
    cursor: pointer;
    transition: all 0.3s ease;
    opacity: 0;
    transform: translateY(20px);
}

.createProfile:hover {
    background: rgba(255, 255, 255, 0.95);
    transform: translateY(-2px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
}

.createProfile::after {
    content: '→';
    font-size: 20px;
    transition: transform 0.3s ease;
}

.createProfile:hover::after {
    transform: translateX(4px);
}

/* Animation classes */
.process.animate .processTitle {
    animation: fadeSlideUp 0.8s ease-out forwards;
}

.process.animate .processSubtitle {
    animation: fadeSlideUp 0.8s ease-out forwards 0.2s;
}

.process.animate .processText {
    animation: fadeSlideUp 0.8s ease-out forwards 0.4s;
}

.process.animate .createProfile {
    animation: fadeSlideUp 0.8s ease-out forwards 0.6s;
}

@keyframes fadeSlideUp {
    from {
        opacity: 0;
        transform: translateY(20px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

/* Smooth Scrolling Effects */
.scrollSection {
    position: relative;
    opacity: 0;
    transform: translateY(50px);
    transition: all 0.8s cubic-bezier(0.4, 0, 0.2, 1);
}

.scrollSection.animate {
    opacity: 1;
    transform: translateY(0);
}

/* Section Transitions */
.process {
    background: linear-gradient(135deg, #66a9ec 0%, #3B82F6 45%, #60A5FA 100%);
    z-index: 2;
}

.expertiseHero {
    background: linear-gradient(120deg, #FFFFFF 0%, #F8FAFC 35%, #F0F9FF 65%, #FFFFFF 100%);
    z-index: 3;
}

.aiLetterHero {
    background: linear-gradient(135deg, #F0F9FF 0%, #E0F2FE 50%, #F0F9FF 100%);
    z-index: 4;
}

.pricingSection {
    background: #FFFFFF;
    z-index: 5;
}

/* Transition Overlays */
.scrollSection::before {
    content: '';
    position: absolute;
    top: -100px;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to bottom, transparent, currentColor);
    opacity: 0.1;
    pointer-events: none;
}

.scrollSection::after {
    content: '';
    position: absolute;
    bottom: -100px;
    left: 0;
    right: 0;
    height: 100px;
    background: linear-gradient(to top, transparent, currentColor);
    opacity: 0.1;
    pointer-events: none;
}

/* Parallax Effect */
.process .mockupWindows {
    transform: translateY(calc(var(--scroll-speed) * -0.1px));
}

.expertiseHero .expertiseMockup {
    transform: translateY(calc(var(--scroll-speed) * -0.15px));
}

.aiLetterHero .letterMockup {
    transform: translateY(calc(var(--scroll-speed) * -0.2px));
}

/* Section-specific animations */
.process.animate .processContent {
    animation: slideUpFade 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.expertiseHero.animate .expertiseContent {
    animation: slideInFromLeft 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.aiLetterHero.animate .aiLetterContent {
    animation: slideInFromRight 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

.pricingSection.animate .pricingGrid {
    animation: fadeScale 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Animation Keyframes */
@keyframes slideUpFade {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromRight {
    from {
        opacity: 0;
        transform: translateX(50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes fadeScale {
    from {
        opacity: 0;
        transform: scale(0.95);
    }
    to {
        opacity: 1;
        transform: scale(1);
    }
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .scrollSection::before,
    .scrollSection::after {
        height: 50px;
    }

    .scrollSection {
        transform: none;
    }
}

/* Hero 1 Mockup Animation */
.heroRight {
    position: relative;
    flex: 1;
    perspective: 1000px;
    opacity: 0;
    /* transform: translateX(100px); */
    animation: slideInWithRotate 1s cubic-bezier(0.4, 0, 0.2, 1) forwards 0.5s;
    padding: 20px;
    max-width: 50%;
    overflow: hidden;
}

/* Process Section (Hero 2) Mockup Animations */
.mockupWindow {
    opacity: 0;
    transform: translateY(20px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.process.animate .mockupWindow:nth-child(1) {
    animation: slideUpFade 0.8s ease-out forwards 0.2s;
}

.process.animate .mockupWindow:nth-child(2) {
    animation: slideUpFade 0.8s ease-out forwards 0.4s;
}

.process.animate .mockupWindow:nth-child(3) {
    animation: slideUpFade 0.8s ease-out forwards 0.6s;
}

/* Expertise Section (Hero 3) Mockup Animation */
.expertiseMockup {
    opacity: 0;
    transform: translateX(50px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.expertiseHero.animate .expertiseMockup {
    animation: slideInRotate 1s cubic-bezier(0.4, 0, 0.2, 1) forwards,
               floatMockup 6s ease-in-out infinite 1s;
}

/* AI Letter Section (Hero 4) Mockup Animation */
.letterMockup {
    opacity: 0;
    transform: translateX(-50px);
    transition: all 0.5s cubic-bezier(0.4, 0, 0.2, 1);
}

.aiLetterHero.animate .letterMockup {
    animation: slideInFromLeft 1s cubic-bezier(0.4, 0, 0.2, 1) forwards;
}

/* Animation Keyframes */
@keyframes slideInWithRotate {
    0% {
        opacity: 0;
        transform: translateX(100px) rotateY(-15deg);
    }
    100% {
        opacity: 1;
        transform: translateX(0) rotateY(-5deg);
    }
}

@keyframes slideUpFade {
    from {
        opacity: 0;
        transform: translateY(30px);
    }
    to {
        opacity: 1;
        transform: translateY(0);
    }
}

@keyframes slideInRotate {
    from {
        opacity: 0;
        transform: translateX(50px) rotateY(-15deg);
    }
    to {
        opacity: 1;
        transform: translateX(0) rotateY(-5deg);
    }
}

@keyframes floatMockup {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

@keyframes slideInFromLeft {
    from {
        opacity: 0;
        transform: translateX(-50px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Hover effects for mockups */
.mockupWindow:hover,
.expertiseMockup:hover,
.letterMockup:hover {
    transform: translateY(-5px);
    box-shadow: 0 20px 40px rgba(0, 0, 0, 0.12);
    transition: all 0.3s ease-out;
}

/* Add shadow transitions */
.mockupWindow,
.expertiseMockup,
.letterMockup {
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    transition: all 0.3s ease-out;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .heroRight,
    .mockupWindow,
    .expertiseMockup,
    .letterMockup {
        transform: none !important;
        animation: simpleFadeIn 1s ease forwards !important;
    }

    @keyframes simpleFadeIn {
        from {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }
}

/* Process Mockup Container */
.processRight {
    position: relative;
    flex: 0.25; /* Further reduced to make it smaller */
    perspective: 600px; /* Reduced perspective for a cuter look */
}

.mockupContainer {
    position: relative;
    /* transform-style: preserve-1d; */
   /* transform: rotateY(0deg) rotateX(0deg);  Reduced rotation for a gentler tilt */
    animation: float 4s ease-in-out infinite; /* Faster animation for a bouncier feel */
    border-radius: 16px; /* Rounded corners for cuteness */
    scale: 0.8; /* Overall size reduction */
}

.progressBarVertical {
    position: relative;
    padding: 40px;
    background: white;
    border-radius: 24px;
    box-shadow: 
        0 4px 6px rgba(0, 0, 0, 0.02),
        0 12px 16px rgba(0, 0, 0, 0.05),
        0 20px 40px rgba(0, 0, 0, 0.08);
}

.progressLine {
    position: absolute;
    left: 59px; /* Adjusted to align with center of circles */
    top: 60px; /* Start from the center of first circle */
    width: 2px;
    height: calc(100% - 120px); /* Adjusted to connect all circles */
    background: #E5E7EB;
}

.progressLine::after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #3B82F6;
    transform: scaleY(0);
    transform-origin: top;
    animation: progressLine 2s ease forwards;
}

.progressSteps {
    position: relative;
    z-index: 1;
    display: flex;
    flex-direction: column;
    gap: 60px; /* Consistent spacing between steps */
}

.step {
    display: flex;
    gap: 24px;
    position: relative;
}

.stepCircle {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background:#3B82F6;
    border: 2px solid #E2E8F0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    color: white;
    position: relative;
    z-index: 2; /* Ensure circles appear above the line */
}

.step.active {
    opacity: 1;
    transform: translateY(0);
    transition: all 0.3s ease;
}

.stepContent {
    flex: 1;
}

.stepContent h4 {
    font-size: 18px;
    font-weight: 600;
    color: #1E293B;
    margin-bottom: 8px;
}

.stepContent p {
    color: #64748B;
    font-size: 14px;
    margin-bottom: 16px;
}

.stepPreview {
    background: #F8FAFC;
    border-radius: 12px;
    padding: 16px;
    margin-top: 12px;
}

.formPreview .inputField {
    height: 12px;
    background: #E2E8F0;
    border-radius: 6px;
    margin-bottom: 12px;
}

.securityBadge {
    display: inline-flex;
    align-items: center;
    gap: 8px;
    padding: 8px 12px;
    background: rgba(34, 197, 94, 0.1);
    border-radius: 8px;
    color: #16A34A;
    font-size: 12px;
}

/* Animations */
@keyframes float {
    0%, 100% {
        transform: rotateY(-5deg) rotateX(2deg) translateY(0);
    }
    50% {
        transform: rotateY(-5deg) rotateX(2deg) translateY(-10px);
    }
}

@keyframes progressLine {
    to {
        transform: scaleY(1);
    }
}

@keyframes stepAppear {
    from {
        opacity: 0;
        transform: translateX(20px);
    }
    to {
        opacity: 1;
        transform: translateX(0);
    }
}

/* Responsive */
@media (max-width: 1024px) {
    .mockupContainer {
        transform: none;
    }
    
    .progressBarVertical {
        padding: 24px;
    }
    
    .progressSteps {
        gap: 40px;
    }
}

.endeavorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.endeavorHeader h5 {
  margin: 0;
  font-size: 15px;
  font-weight: 600;
  color: #1F2937;
}

.endeavorCheck {
  appearance: none;
  -webkit-appearance: none;
  width: 20px;
  height: 20px;
  border: 2px solid #D1D5DB;
  border-radius: 6px;
  background: white;
  cursor: pointer;
  position: relative;
  transition: all 0.2s ease;
}

.endeavorCheck:checked {
  background: #3B82F6;
  border-color: #3B82F6;
}

.endeavorCheck:checked::after {
  content: '';
  position: absolute;
  left: 6px;
  top: 3px;
  width: 4px;
  height: 8px;
  border: solid white;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.endeavorCheck:hover {
  border-color: #3B82F6;
}

.endeavorDetails p {
  font-size: 14px;
  color: #6B7280;
  line-height: 1.5;
  margin: 0;
}

.checkItem {
  color: #22C55E; /* Modern green color */
  font-weight: 500;
  margin-bottom: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

.checkItem::before {
  content: "✓";
  font-weight: bold;
  color: #22C55E;
}

.letterTo {
  font-weight: 500;
  color: #111827;
  font-size: 16px;
  margin: 24px 0;
}

@keyframes fadeInScale {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

.letterContent p, 
.letterTo, 
.letterDate, 
.letterRef,
.letterHighlight,
.letterSignature {
  opacity: 1; /* Ensure content stays visible */
  animation: fadeIn 0.5s ease-out forwards;
}

/* Remove the typing animation that might hide content */
.letterContent p::after {
  display: none;
}

/* Keep other animations but ensure they don't affect visibility */
.letterMockup {
  opacity: 1;
  animation: fadeInUp 0.8s ease-out forwards;
}

/* Fixed width container for all hero sections */
.hero,
.expertiseHero,
.aiLetterHero,
.process {
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    margin-right: calc(-50vw + 50%);
    overflow: hidden;
}

/* Content container for all heroes */
.hero > div,
.expertiseHero > div,
.aiLetterHero > div,
.process > div {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 120px 80px;
}

/* Dashboard image container */
.heroRight {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    position: relative;
    perspective: 1000px;
}

.dashboardImage {
    width: 100%;
    height: auto;
    min-width: 600px;
    max-width: 720px;
    object-fit: contain;
}

/* Logo styles with fixed dimensions */
.trustedBy {
    margin-top: 80px;
    width: 100%;
}

.logoGroup {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 32px;
    width: 100%;
    min-width: max-content;
}

.logoGroup img {
    object-fit: contain;
}

.logoGroup img[alt="Meta logo"] {
    width: 80px;
    height: 80px;
    min-width: 80px;
    max-width: 80px;
}

.logoGroup img[alt="McKinsey logo"] {
    width: 80px;
    height: 80px;
    min-width: 80px;
    max-width: 80px;
}

.logoGroup img[alt="TikTok logo"] {
    width: 90px;
    height: 30px;
    min-width: 90px;
    max-width: 90px;
}

.divider {
    min-width: 1px;
    width: 1px;
    height: 28px;
    background: rgba(0, 0, 0, 0.1);
    margin: 0 16px;
}

/* Responsive adjustments */
@media (max-width: 1200px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        padding: 80px 40px;
    }

    .heroRight {
        min-width: 500px;
    }

    .dashboardImage {
        min-width: 500px;
    }
}

@media (max-width: 768px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        padding: 60px 20px;
    }

    .heroRight {
        width: 100%;
        min-width: 320px;
        max-width: 500px;
        margin: 0 auto;
    }

    .dashboardImage {
        min-width: 320px;
        max-width: 500px;
        transform: none;
    }

    .trustedBy {
        margin-top: 40px;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
    }

    .logoGroup {
        padding: 0 20px;
        justify-content: flex-start;
    }
}

@media screen and (max-width: 390px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        padding: 40px 16px;
        gap: 24px;
    }

    .heroLeft,
    .expertiseLeft,
    .aiLetterLeft,
    .processLeft {
        padding: 0 12px;
    }

    .heroRight,
    .expertiseRight,
    .aiLetterRight,
    .processRight {
        padding: 0 12px;
    }

    .statsText {
        font-size: 11px;
        letter-spacing: 0.03em;
    }
}

/* Hero sections with reduced gap and better centering */
.hero > div,
.expertiseHero > div,
.aiLetterHero > div,
.process > div {
    max-width: 1440px;
    width: 100%;
    margin: 0 auto;
    padding: 120px 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px; /* Reduced gap between left and right content */
}

/* Left side content alignment */

.expertiseLeft,
.aiLetterLeft,
.processLeft {
    flex: 1;
    max-width: 480px; /* Slightly reduced to bring content closer */
    padding-right: 0; /* Removed padding to reduce space */
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

/* Right side content alignment */
.heroRight,
.expertiseRight,
.aiLetterRight,
.processRight {
    flex: 1;
    max-width: 580px;
    display: flex;
    justify-content: center;
    align-items: center;
}

/* Stats text fix */
.statsText {
    font-family: "Roboto Mono", "Courier New", monospace !important; /* Fallback fonts */
    font-size: 14px;
    color: #374151;
    line-height: 1.4;
    text-transform: uppercase !important;
    letter-spacing: 0.05em;
    font-weight: 500;
}

/* Responsive adjustments */
@media (max-width: 1400px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        padding: 100px 60px;
        gap: 16px;
    }
}

@media (max-width: 1200px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        padding: 80px 40px;
        flex-direction: column;
        gap: 40px;
    }

    .expertiseLeft,
    .aiLetterLeft,
    .processLeft {
        max-width: 600px;
        align-items: center;
        text-align: center;
    }

    .heroRight,
    .expertiseRight,
    .aiLetterRight,
    .processRight {
        max-width: 600px;
        width: 100%;
    }

    .statsText {
        font-size: 13px;
    }
}

@media (max-width: 768px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        padding: 60px 20px;
        gap: 32px;
    }

    .heroLeft,
    .expertiseLeft,
    .aiLetterLeft,
    .processLeft {
        max-width: 100%;
        padding: 0 20px;
    }

    .heroRight,
    .expertiseRight,
    .aiLetterRight,
    .processRight {
        max-width: 100%;
        padding: 0 20px;
    }

    .statsText {
        font-size: 12px;
    }
}

@media screen and (max-width: 390px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        padding: 40px 16px;
        gap: 24px;
    }

    .heroLeft,
    .expertiseLeft,
    .aiLetterLeft,
    .processLeft {
        padding: 0 12px;
    }

    .heroRight,
    .expertiseRight,
    .aiLetterRight,
    .processRight {
        padding: 0 12px;
    }

    .statsText {
        font-size: 11px;
        letter-spacing: 0.03em;
    }
}

/* Center alignment for all screen sizes */
@media (min-width: 1401px) {
    .hero > div,
    .expertiseHero > div,
    .aiLetterHero > div,
    .process > div {
        justify-content: center;
        gap: 20px;
    }

    .heroLeft,
    .expertiseLeft,
    .aiLetterLeft,
    .processLeft {
        margin-right: 0;
    }

    .heroRight,
    .expertiseRight,
    .aiLetterRight,
    .processRight {
        margin-left: 0;
    }
}

