.footer {
    background: #2A89E9;
    padding: 80px 40px 40px;
    color: #FFFFFF;
}

.footer__content {
    max-width: 1440px;
    margin: 0 auto;
    padding: 0 24px;
}

.footer__main {
    display: flex;
    justify-content: space-between;
    gap: 120px;
    margin-bottom: 60px;
}

.footer__brand {
    max-width: 320px;
}

.footer__logo {
    height: 40px;
    margin-bottom: 32px;
}

.footer__contact {
    font-size: 18px;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.95);
    font-weight: 500;
}

.footer__links {
    display: flex;
    gap: 120px;
}

.footer__column {
    display: flex;
    flex-direction: column;
    gap: 20px;
}

.footer__heading {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 16px;
    letter-spacing: 0.5px;
    text-transform: uppercase;
}

.footer__column a {
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    font-size: 16px;
    line-height: 1.6;
    transition: all 0.3s ease;
}

.footer__column a:hover {
    color: #FFFFFF;
}

.footer__divider {
    height: 1px;
    background: rgba(255, 255, 255, 0.1);
    margin: 32px 0;
}

.footer__bottom {
    text-align: center;
}

.footer__copyright {
    font-size: 14px;
    color: rgba(255, 255, 255, 0.7);
}

@media (max-width: 768px) {
    .footer {
        padding: 48px 24px;
    }

    .footer__main {
        flex-direction: column;
        gap: 40px;
    }

    .footer__links {
        flex-direction: column;
        gap: 32px;
    }

    .footer__brand {
        text-align: center;
        margin: 0 auto;
    }
}