.title {
    position: relative;
    line-height: 125%;
}
.text {
    flex: 1;
    position: relative;
    line-height: 125%;
}
.inputField {
    width: 353px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d8dadc;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 18px 16px;
    font-size: 16px;
}
.input {
    position: absolute;
    top: 231px;
    left: 536px;
    height: 88px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 6px;
}
.icon {
    width: 20px;
    position: relative;
    height: 20px;
    overflow: hidden;
    flex-shrink: 0;
}
.inputField1 {
    width: 100%;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d8dadc;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    padding: 18px 16px;
    gap: 10px;
    font-size: 16px;
}
.textContainer {
    width: 370px;
    background-color: #fcfcfc00;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 10px;
    font-size: 16px;
}
.input1 {
    position: absolute;
    top: 343px;
    left: 536px;
    height: 89px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 6px;
}
.hiWelcome {
    position: absolute;
    top: 146px;
    left: 536px;
    font-size: 30px;
    letter-spacing: -0.01em;
    line-height: 130%;
    display: inline-block;
    font-family: Poppins;
    width: 201px;
    height: 43px;
}
.rememberMe {
    position: absolute;
    top: 449px;
    left: 536px;
    line-height: 120%;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 140px;
    height: 20px;
}
.image297Icon {
    position: absolute;
    top: 148px;
    left: 744px;
    width: 30px;
    height: 33px;
    object-fit: cover;
}
.span {
    color: #000;
}
.signUp1 {
    font-weight: 600;
    margin-left: 8px;
}
.dontHaveAnContainer1 {
    width: 100%;
    display: flex;
    justify-content: center;
}
.dontHaveAnContainer {
    position: absolute;
    top: 600px;
    left: 536px;
    line-height: 125%;
    display: flex;
    align-items: flex-end;
    width: 353px;
    height: 20px;
    color: rgba(0, 0, 0, 0.7);
}
.forgotPassword {
    position: absolute;
    top: 449px;
    left: 770px;
    line-height: 125%;
    display: flex;
    align-items: flex-end;
    width: 119px;
    height: 20px;
}
.iconButton {
    background: #ffffff00;
    border-color: #fcfcfc00;
    color: #3c5a99;
}
.orWith {
    position: absolute;
    top: -50px;
    left: 155px;
    line-height: 125%;
    display: flex;
    align-items: flex-end;
    width: 49px;
    height: 20px;
}
.groupChild {
    position: absolute;
    top: -40.5px;
    left: 205.5px;
    border-top: 1px solid #d8dadc;
    box-sizing: border-box;
    width: 133px;
    height: 1px;
}
.groupItem {
    position: absolute;
    top: -40.5px;
    left: -0.5px;
    border-top: 1px solid #d8dadc;
    box-sizing: border-box;
    width: 133px;
    height: 1px;
}
.orWithParent {
    position: absolute;
    top: 681px;
    left: 536px;
    width: 353px;
    height: 20px;
    color: rgba(0, 0, 0, 0.7);
}
.checkboxOn {
    position: absolute;
    top: 448px;
    left: 536px;
    width: 20px;
    height: 22px;
}

.buttonPrimary {
    position: absolute;
    top: 517px;
    left: 536px;
    border-radius: 10px;
    background-color: #3c5a99;
    width: 353px;
    height: 61px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 17px 152px; */
    box-sizing: border-box;
    text-align: center;
    font-size: 20px;
    color: #fff;
    border: 1px solid #d8dadc;
}
.google {
    position: relative;
    line-height: 125%;
    font-weight: 500;
}
.socialIconFacebookParent {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    gap: 12px;
}
.buttonWithCenteredIcon {
    position: absolute;
    top: 675px;
    left: 536px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d8dadc;
    box-sizing: border-box;
    width: 170px;
    height: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 18px 45px;
    color: #000;
}
.buttonWithCenteredIcon1 {
    position: absolute;
    top: 675px;
    left: 536px;
    border-radius: 10px;
    background-color: #fff;
    border: 1px solid #d8dadc00;
    box-sizing: border-box;
    width: 354px;
    height: 62px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.navbarIcon {
    position: absolute;
    top: 50px;
    left: 101px;
    width: 185px;
    height: 54px;
}
.login {
    width: 100%;
    top: -10px;
    position: relative;
    background-color: #fff;
    height: 1024px;
    overflow: hidden;
    text-align: left;
    font-size: 14px;
    color: #3c5a99;
    font-family: Inter;
}

.checkbox {
    margin: 0;
    cursor: pointer;
}

.rememberMeText {
    cursor: pointer;
}

.errorPopup {
    position: fixed;
    top: 24px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #fff;
    color: #ff4d4f;
    padding: 18px 24px;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.15);
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
    border: 1px solid #ffccc7;
    font-family: Inter;
    font-size: 14px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 12px;
    width: min(400px, 90vw);
    max-width: 90vw;
    margin: 0 16px;
    box-sizing: border-box;
    background-color: #fff2f0;
}

.errorIcon {
    color: #ff4d4f;
    font-size: 18px;
}

.errorMessage {
    flex: 1;
}

.closeButton {
    background: none;
    border: none;
    color: #999;
    cursor: pointer;
    padding: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
}

.closeButton:hover {
    color: #666;
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translate(-50%, -100%);
    }
    to {
        opacity: 1;
        transform: translate(-50%, 0);
    }
}

