.container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  background-color: #f8fafc;
}

.pageLayout {
  display: flex;
  padding: 20px;
  gap: 20px;
}

.mainContent {
  flex: 1;
  background-color: #ffffff00;
  border-radius: 12px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.wrapper {
  position: relative;
  flex: 1;
  padding: 35px 24px;
  height: calc(100vh - 112px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
}

.title {
  position: relative;
  top: 10px;
  left: 0%;
  font-size: 30px;
  line-height: 25px;
  font-weight: 500;
  font-family: Roboto;
  color: #004788;
  margin-bottom: 32px;
  padding-left: 24px;
}

/* AI Generator Card - Updated to match design */
.aiGeneratorCard {
  min-width: 575px;
  background-color: #fff;
  border: 1px solid #dde2e5;
  border-radius: 20px;
  padding: 37px 43px;
  margin-bottom: 32px;
  position: relative;
  top: 20px;
  display: flex;
  flex-direction: column;
}

.generatorHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 16px;
  border-bottom: 1px solid #dde2e5;
  margin-bottom: 24px;
}

.headerLeft {
  display: flex;
  align-items: center;
  gap: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #1a1a1a;
  font-family: 'Open Sans', sans-serif;
}

.generatorContent {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 0 8px;
  justify-content: center;
}

.generatorPrompt {
  font-size: 16px;
  color: #374151;
  font-family: 'Open Sans', sans-serif;
  max-width: 60%;
}

.generateButton {
  position: relative;
  width: 200px;
  border-radius: 12px;
  padding: 14px 24px;
  font-size: 16px;
  font-family: 'Inter', sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1e70bb;
  color: #fff;
  border: none;
  box-shadow: 0 2px 4px rgba(30, 112, 187, 0.2);
  transition: all 0.2s ease;
  margin: 0 auto;
  margin-top: 40px;
}

.generateButton:hover {
  background-color: #1a62a3;
  transform: translateY(-1px);
  box-shadow: 0 4px 6px rgba(30, 112, 187, 0.25);
}

.generateButton:disabled {
  background-color: #93c5fd;
  cursor: not-allowed;
}

/* Update collapse button styling */
.collapseButton {
  background: none;
  border: none;
  color: #6b7280;
  cursor: pointer;
  padding: 4px;
  font-size: 16px;
}

/* Add sparkle icon styling */
.sparkleIcon {
  color: #1e70bb;
  font-size: 20px;
}

/* Loading States */
.loadingOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.loadingSpinner {
  background: white;
  padding: 30px;
  border-radius: 12px;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.spinnerIcon {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #1e70bb;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin: 0 auto 15px;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* Progress Bar */
.progressBar {
  width: 100%;
  height: 8px;
  background-color: #e2e8f0;
  border-radius: 4px;
  margin: 16px 0;
  overflow: hidden;
}

.progress {
  height: 100%;
  background-color: #1e70bb;
  border-radius: 4px;
  width: 67%;
  transition: width 0.3s ease;
}

.generatorBox {
  width: 100%;
  text-align: center;
  font-family: 'Open Sans', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.generatorBox p {
  color: #4a5568;
  margin-bottom: 20px;
  font-size: 16px;
  line-height: 1.5;
  text-align: center;
}

.buttoncontainer {
  display: flex;
  justify-content: flex-end;
  gap: 12px;
  margin-top: 32px;
  padding-top: 16px;
  border-top: 1px solid #E6E6E6;
}
  