.header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 48px;
    width: 100%;
    height: 64px;
    background: #FFFFFF;
    border-bottom: 1px solid #E5E7EB;
}

.navigation {
    display: flex;
    align-items: center;
    height: 100%;
    flex: 1;
}

.navLinks {
    display: flex;
    align-items: center;
    gap: 32px;
    height: 100%;
    margin-right: auto;
    padding-left: 48px;
}

.logoContainer {
    display: flex;
    align-items: center;
    height: 100%;
    padding-right: 32px;
    margin-right: 32px;
}

.logo {
    width: 149px;
    height: 31px;
    /* object-fit: contain; */
}

.navItem {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 8px;
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #4B5563;
    cursor: pointer;
    position: relative;
    transition: color 0.2s ease;
}

.navItem:hover {
    color: #111827;
}

.navItem.active {
    color: #111827;
}

.navItem.active::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #111827;
}

.headerActions {
    display: flex;
    align-items: center;
    gap: 16px;
}

.iconButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 40px;
    height: 40px;
    border: none;
    background: transparent;
    border-radius: 20px;
    cursor: pointer;
    transition: background-color 0.2s ease;
}

.iconButton:hover {
    background-color: #F3F4F6;
}

.icon {
    width: 20px;
    height: 20px;
    color: #4B5563;
}

.profileImage {
    height: 32px;
    min-width: 32px;
    border-radius: 6px;
    overflow: hidden;
    cursor: pointer;
    padding: 0 8px;
    display: flex;
    align-items: center;
}

.avatar {
    width: 32px;
    height: 32px;
    object-fit: cover;
}

@media (max-width: 768px) {
    .header {
        padding: 0 16px;
    }

    .navLinks {
        gap: 16px;
    }

    .navItem {
        font-size: 14px;
    }

    .headerActions {
        gap: 12px;
    }
}