.forgotPasswordContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 100vh;
    background-color: #f9f9f900; 
    padding: 20px;
  }
  
  .navbarIcon {
    position: absolute;
    top: 50px;
    left: 101px;
    width: 185px;
    height: 54px;
}
  
  .form {
    width: 100%;
    max-width: 400px;
    background: #fff;
    padding: 30px;
    border-radius: 10px;
    /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  }
  
  .title {
    text-align: center;
    margin-bottom: 20px;
    font-size: 30px;
    color: #3c5a99;
    font-family: "Poppins", sans-serif;
  }
  
  .inputContainer {
    margin-bottom: 15px;
  }
  
  .label {
    display: block;
    margin-bottom: 8px;
    font-size: 14px;
    color: #3c5a99;
  }
  
  .inputField {
    width: 100%;
    padding: 12px;
    border-radius: 5px;
    border: 1px solid #d8dadc;
    box-sizing: border-box;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.7);
  }
  
  .inputField:focus {
    outline: none;
    border-color: #3c5a99;
    box-shadow: 0 0 5px rgba(60, 90, 153, 0.2);
  }
  
  .buttonPrimary {
    width: 100%;
    padding: 12px;
    background-color: #3c5a99;
    color: #fff;
    border: none;
    border-radius: 5px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .buttonPrimary:hover {
    background-color: #2e497a;
  }
  
  .errorText {
    color: red;
    font-size: 12px;
    margin-top: 5px;
  }
  
  .successText {
    color: green;
    font-size: 14px;
    margin-bottom: 15px;
    text-align: center;
  }
  