.menu {
    position: relative;
    top: 24px;
    margin-left: 10px;
    width: 280px;
    background-color: #ffffff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    padding: 24px 16px;
    display: flex;
    flex-direction: column;
    gap: 32px;
    height: calc(100vh - 64px);
    overflow-y: auto;
}

.sidebarHeader {
    display: flex;
    align-items: center;
    padding: 0 8px;
    margin-bottom: 24px;
}

.logoWrapper {
    display: flex;
    align-items: center;
    gap: 10px;
}

.logo {
    width: 38px;
    height: 27px;
}

.logoText {
    font-family: 'DM Mono', monospace;
    font-size: 24px;
    font-weight: 500;
    line-height: 31px;
    color: #000000;
}

.menuItems {
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: 8px;
}

.listManu {
    display: flex;
    align-items: center;
    padding: 12px 16px;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.2s ease;
    background-color: transparent;
}

.listManu:hover {
    background-color: #326bdc6d;
}

.listManu.active {
    background-color: #D1D5DB;
}

.content {
    display: flex;
    align-items: center;
    gap: 12px;
    width: 100%;
}

.icons {
    width: 20px;
    height: 20px;
    color: #374151;
}

.text2 {
    font-family: 'Inter', sans-serif;
    font-size: 15px;
    font-weight: 500;
    color: #111827;
}

.dropdown {
    display: flex;
    flex-direction: column;
    gap: 4px;
    margin-left: 8px;
}

.dropdownItem {
    display: flex;
    align-items: center;
    gap: 12px;
    padding: 10px 16px;
    padding-left: 44px;
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #374151;
    cursor: pointer;
    transition: all 0.2s ease;
    border-radius: 6px;
}

.dropdownItem:hover {
    background-color: #326bdc6d;
}

.dropdownItem.active {
    background-color: #D1D5DB;
    color: #111827;
}

.sidebarFooter {
    border-top: 1px solid #E5E7EB;
    padding-top: 16px;
}

.userInfo {
    display: flex;
    flex-direction: column;
    gap: 8px;
    padding: 0 8px;
}

.userEmail {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #6B7280;
}

.logoutButton {
    font-family: 'Inter', sans-serif;
    font-size: 14px;
    color: #101011;
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;
    text-align: left;
    transition: all 0.2s ease;
}

.logoutButton:hover {
    color: #326bdc6d;
}

.logoutButton:disabled {
    color: #9CA3AF;
    cursor: not-allowed;
}