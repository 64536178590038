.container {
  width: 100%;
  min-height: 100vh;
  background-color: #f8f9fa;
}

.pageLayout {
  display: flex;
  max-width: 1200px;
  margin: 0 auto;
}

.mainContent {
  flex: 1;
  padding: 40px;
  background-color: white;
  border-radius: 8px;
  margin: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.title {
    position: relative;
    top: 10px;
    left: 0%;
    font-size: 30px;
    line-height: 25px;
    font-weight: 500;
    font-family: Roboto;
    color: #004788;
    margin-bottom: 32px;
    padding-left: 24px;
  }

.cartItems {
  margin: 20px 0;
  
}

.cartItem {
  display: grid;
  grid-template-columns: 2fr 1fr 1fr 1fr;
  align-items: center;
  gap: 20px;
  padding: 24px;
  border: 1px solid #eee;
  border-radius: 8px;
  margin-bottom: 15px;
  background-color: #fff;
  transition: all 0.3s ease;
}

.cartItem:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.itemInfo {
  flex: 1;
}

.nameAndControls {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.itemName {
  font-size: 18px;
  color: #004788;
  font-weight: 500;
  padding-right: 20px;
  font-family: sans-serif;
}

.quantityControls {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.quantityButton {
  width: 32px;
  height: 32px;
  border: 1px solid #e2e8f0;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  padding: 0;
}

.quantityButton:hover:not(:disabled) {
  background-color: #f7fafc;
  border-color: #004788;
  color: #004788;
}

.quantityButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #f7fafc;
}

.buttonSymbol {
  font-size: 18px;
  line-height: 1;
  color: #2d3748;
  user-select: none;
}

.quantity {
  font-size: 16px;
  min-width: 24px;
  text-align: center;
  color: #2d3748;
  font-weight: 500;
}

.itemQuantity {
  color: #666;
  font-size: 14px;
}

.itemActions {
  display: flex;
  align-items: center;
  gap: 20px;
}

.itemPrice {
  font-size: 18px;
  font-weight: bold;
  color: #2c5282;
  margin: 0;
}

.pricePerItem {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.removeButton {
  background-color: white !important;
  color: #e53e3e !important;
  border: 1px solid #e53e3e !important;
  padding: 8px 16px !important;
  border-radius: 6px !important;
  transition: all 0.2s ease !important;
  justify-self: end;
}

.removeButton:hover {
  background-color: #fff5f5 !important;
  border-color: #c53030 !important;
  color: #c53030 !important;
}

.cartSummary {
  margin-top: 40px;
  padding: 20px;
  border-top: 2px solid #f0f0f0;
}

.summaryDetails {
  margin-bottom: 20px;
}

.summaryRow {
  display: flex;
  justify-content: space-between;
  padding: 10px 0;
  font-size: 16px;
  color: #4a5568;
}

.totalAmount {
  font-size: 24px;
  font-weight: bold;
  color: #2c5282;
}

.checkoutButton {
  width: 100%;
  padding: 15px !important;
  font-size: 18px !important;
  background-color: #004788 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.checkoutButton:hover {
  background-color: #1d67e7 !important;
}

.emptyCart {
  text-align: center;
  margin: 60px 0;
  padding: 40px;
  background-color: #f8f9fa;
  border-radius: 8px;
}

.emptyMessage {
  font-size: 20px;
  color: #4a5568;
  margin-bottom: 20px;
}

.returnButton {
  width: 100%;
  padding: 15px !important;
  font-size: 18px !important;
  background-color: #004788 !important;
  color: white !important;
  border: none !important;
  border-radius: 8px !important;
  cursor: pointer;
  transition: all 0.3s ease;
}

.returnButton:hover {
  background-color: #1d67e7 !important;
}

.buttonGroup {
  display: flex;
  gap: 1rem;
  margin-top: 1.5rem;
  justify-content: space-between;
}

.primaryButton {
  background-color: #007bff;
  color: white;
  padding: 0.75rem 1.5rem;
  flex: 1;
  max-width: 250px;
}

.secondaryButton {
  background-color: #6c757d;
  color: white;
  padding: 0.75rem 1.5rem;
  flex: 1;
  max-width: 250px;
}

.primaryButton:hover {
  background-color: #0056b3;
}

.secondaryButton:hover {
  background-color: #5a6268;
}

.priceContainer {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.itemPrice {
  font-size: 18px;
  font-weight: bold;
  color: #2c5282;
  margin: 0;
}

.pricePerItem {
  font-size: 14px;
  color: #666;
  margin-top: 4px;
}

.quantityControls {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-top: 12px;
}

.quantityButton {
  width: 32px;
  height: 32px;
  border: 1px solid #e2e8f0;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s ease;
  padding: 0;
}

.quantityButton:hover:not(:disabled) {
  background-color: #f7fafc;
  border-color: #cbd5e0;
}

.quantityButton:disabled {
  opacity: 0.5;
  cursor: not-allowed;
  background-color: #f7fafc;
}

.buttonSymbol {
  font-size: 18px;
  line-height: 1;
  color: #2d3748;
  user-select: none;
}

.quantity {
  font-size: 16px;
  min-width: 24px;
  text-align: center;
  color: #2d3748;
  font-weight: 500;
}

.loadingContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 80px 0;
  background-color: #f8f9fa;
  border-radius: 12px;
  margin: 40px 0;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
}

.loadingSpinner {
  width: 48px;
  height: 48px;
  border: 4px solid #e2e8f0;
  border-top: 4px solid #004788;
  border-radius: 50%;
  animation: spin 1s linear infinite;
  margin-bottom: 24px;
}

.loadingTextContainer {
  display: flex;
  align-items: center;
}

.loadingText {
  font-size: 24px;
  color: #004788;
  font-weight: 500;
  font-family: sans-serif;
  letter-spacing: 0.5px;
}

.loadingDots {
  display: inline-flex;
  margin-left: 4px;
}

.loadingDots span {
  opacity: 0;
  font-size: 24px;
  color: #004788;
  animation: dots 1.4s infinite;
  margin-left: 2px;
}

.loadingDots span:nth-child(2) {
  animation-delay: 0.2s;
}

.loadingDots span:nth-child(3) {
  animation-delay: 0.4s;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes dots {
  0%, 20% {
    opacity: 0;
    transform: translateY(0);
  }
  50% {
    opacity: 1;
    transform: translateY(-4px);
  }
  80%, 100% {
    opacity: 0;
    transform: translateY(0);
  }
} 