.alertOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    backdrop-filter: blur(4px);
  }
  
  .alertContainer {
    background-color: white;
    padding: 2rem;
    border-radius: 12px;
    box-shadow: 0 4px 24px rgba(0, 0, 0, 0.15);
    max-width: 400px;
    width: 90%;
    text-align: center;
    animation: slideDown 0.3s ease-out;
  }
  
  .alertMessage {
    color: #333;
    font-size: 16px;
    margin-bottom: 1.5rem;
    line-height: 1.5;
  }
  
  .alertButtons {
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
  
  .confirmButton, .closeButton {
    padding: 0.75rem 1.5rem;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    transition: all 0.2s ease;
    min-width: 100px;
  }
  
  .confirmButton {
    background-color: #1e70bb;
    color: white;
    border: none;
    box-shadow: 0 2px 8px rgba(30, 112, 187, 0.3);
  }
  
  .closeButton {
    background-color: #f8f9fa;
    color: #666;
    border: 1px solid #ddd;
  }
  
  .confirmButton:hover {
    background-color: #1859a3;
    transform: translateY(-1px);
  }
  
  .closeButton:hover {
    background-color: #e9ecef;
    transform: translateY(-1px);
  }
  
  .error {
    border-top: 4px solid #dc3545;
  }
  
  .success {
    border-top: 4px solid #28a745;
  }
  
  @keyframes slideDown {
    from {
      opacity: 0;
      transform: translateY(-20px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }