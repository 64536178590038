.container {
    max-width: 800px;
    margin: 0 auto;
    padding: 40px 20px;
    font-family: system-ui, -apple-system, sans-serif;
    color: #333;
    line-height: 1.6;
  }
  
  .section {
    margin-bottom: 40px;
  }
  
  h1 {
    font-size: 32px;
    color: #2c5282;
    margin-bottom: 20px;
  }
  
  h2 {
    font-size: 24px;
    color: #2d3748;
    margin: 30px 0 20px;
    padding-bottom: 10px;
    border-bottom: 2px solid #edf2f7;
  }
  
  h3 {
    font-size: 20px;
    color: #4a5568;
    margin: 25px 0 15px;
  }
  
  p {
    margin: 15px 0;
    color: #4a5568;
  }
  
  ul {
    margin: 15px 0;
    padding-left: 20px;
  }
  
  li {
    margin: 8px 0;
    color: #4a5568;
  }
  
  @media (max-width: 768px) {
    .container {
      padding: 20px 15px;
    }
  
    h1 {
      font-size: 28px;
    }
  
    h2 {
      font-size: 22px;
    }
  
    h3 {
      font-size: 18px;
    }
  }